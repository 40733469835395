import { useState, useEffect } from "react";
import "./Forms.scss";

export default function Select({
  name,
  label,
  defaultValue,
  onChange,
  options,
  ...rest
}) {
  const [selected, setSelect] = useState({
    label: "",
    value: "",
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    setSelect(
      options.find((option) => option.value === defaultValue) || {
        label: "",
        value: "",
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChange = (selectedOption) => {
    setSelect(selectedOption);
    onChange(selectedOption.value);
    setShow(false);
  };

  const toggleDropdown = () => {
    setShow((prevShow) => !prevShow);
  };

  // Use useEffect to add/remove the click event listener
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (show && !e.target.closest(`.dropdown`)) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    // Cleanup on unmount or when `show` changes
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [show]);

  return (
    <div className="mb-4 dropdown-select">
      {label && (
        <label htmlFor={`${name}-button`} className="form-label">
          {label}
        </label>
      )}
      <input type="hidden" id={name} value={selected.value} />
      <div className="dropdown w-100 d-block">
        <button
          id={`${name}-button`}
          type="button"
          aria-expanded={show}
          onClick={toggleDropdown}
          className={`btn btn-light px-3 dropdown-toggle ${
            show ? " show" : "hide"
          } ${rest?.className}`}
        >
          {selected.label || "Select"}
        </button>
        <ul className={`dropdown-menu px-2${show ? " show" : ""}`}>
          {options.map((option) => (
            <li className="mb-1" key={option.value}>
              <div
                className={`dropdown-item rounded ${
                  selected.value === option.value
                    ? "active cursor-default"
                    : "cursor-pointer"
                }`}
                onClick={() => handleChange(option)}
              >
                {option.label}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
