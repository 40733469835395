import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { debounce } from "lodash";

const InputHeaders = ({ onChange, defaultHeaders }) => {
  const { t } = useTranslation();
  const [headers, setHeaders] = useState([]);
  const [viewExpanded, setViewExpanded] = useState(false);

  useEffect(() => {
    if (defaultHeaders && Object.keys(defaultHeaders).length > 0) {
      const newHeaders = Object.entries(defaultHeaders).flatMap(
        ([key, value]) => {
          const values = Array.isArray(value) ? value : [value];
          return values.map((v) => ({ label: key, value: v }));
        }
      );
      setHeaders(newHeaders);
    }
  }, [defaultHeaders]);

  const updateParent = useCallback(
    (updatedHeaders) => {
      const groupedHeaders = updatedHeaders.reduce((acc, { label, value }) => {
        if (!acc[label]) {
          acc[label] = [];
        }
        acc[label].push(value);
        return acc;
      }, {});

      const formattedHeaders = Object.fromEntries(
        Object.entries(groupedHeaders).map(([key, values]) => [
          key,
          values.length > 1 ? values : values[0],
        ])
      );
      onChange(formattedHeaders);
    },
    [onChange]
  );

  const debouncedUpdateParent = useMemo(
    () => debounce(updateParent, 300),
    [updateParent]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateParent.cancel();
    };
  }, [debouncedUpdateParent]);

  const addHeader = useCallback(() => {
    setHeaders((prev) => [...prev, { label: "", value: "" }]);
  }, []);

  const removeHeader = useCallback(
    (indexToRemove) => {
      setHeaders((prev) => {
        const newHeaders = prev.filter((_, index) => index !== indexToRemove);
        debouncedUpdateParent(newHeaders);
        return newHeaders;
      });
    },
    [debouncedUpdateParent]
  );

  const updateHeader = useCallback(
    (index, newLabel, newValue) => {
      setHeaders((prev) => {
        const newHeaders = prev.map((header, i) =>
          i === index ? { label: newLabel, value: newValue } : header
        );
        debouncedUpdateParent(newHeaders);
        return newHeaders;
      });
    },
    [debouncedUpdateParent]
  );

  return (
    <div className="multi-field">
      <div className="d-flex justify-content-between align-items-center">
        <label htmlFor="headers" className="form-label mb-0">
          <span>{t("Headers")}</span>
        </label>
      </div>
      <div className={viewExpanded ? "expanded" : "resumed"}>
        {headers.map((header, index) => (
          <div
            key={`header-${index}`}
            className="mt-2 d-flex gap-2 align-items-center"
          >
            <div className="w-50">
              <Form.Control
                type="text"
                id={`header-key-${index}`}
                placeholder="Key"
                value={header.label}
                onChange={(e) =>
                  updateHeader(index, e.target.value, header.value)
                }
              />
            </div>
            <div>:</div>
            <div className="w-100">
              <Form.Control
                type="text"
                id={`header-value-${index}`}
                placeholder="Value"
                value={header.value}
                onChange={(e) =>
                  updateHeader(index, header.label, e.target.value)
                }
              />
            </div>
            <Button
              variant="transparent"
              className="d-flex align-items-center icon small p-2"
              onClick={() => removeHeader(index)}
            >
              <i className="ti ti-trash fs-4 text-danger" />
            </Button>
          </div>
        ))}
      </div>
      {headers.length > 5 && (
        <div
          className={`mt-2 text-center ${
            viewExpanded ? "expanded" : "shadow-bottom"
          }`}
        >
          <button
            className="btn btn-transparent px-2 py-1 btn-sm"
            onClick={() => setViewExpanded((prev) => !prev)}
          >
            {viewExpanded ? t("Collapse") : t("Expand")}
          </button>
        </div>
      )}
      <Button
        variant="transparent"
        className="px-2 py-1 mt-2 d-flex align-items-center gap-1"
        onClick={addHeader}
      >
        <i className="ti ti-plus fs-6 text-primary" />
        {t("Add header")}
      </Button>
    </div>
  );
};

export default InputHeaders;
