import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useFetchList } from "../../services/requestService";
import routes from "../../configs/routes";
import { isObjectEmpty } from "../../utils/helpers";
import { Error, Empty } from "../../components/Requests/Messages";
import Accordion from "react-bootstrap/Accordion";
import Heading from "../../components/Heading/Heading";
import CollectionNav from "../../components/Heading/CollectionNav";
import SearchBox from "../../components/SearchBox/SearchBox";
import ModalClear from "../../components/Requests/Item/ModalClear";
import StatusBadge from "../../components/Endpoints/Item/StatusBadge";
import { apiDomain } from "../../utils/helpers";
import "./Requests.scss";

const RequestList = () => {
  const { t } = useTranslation();
  const { uuid, requestUuid } = useParams();
  const { data, isPending, error } = useFetchList(uuid);
  const [requests, setRequests] = useState([]);
  const [request, setRequest] = useState({});
  const [collection, setCollection] = useState({});

  useEffect(() => {
    const { requests = [] } = data?.content || {};
    const { content: collection } = data || {};
    setCollection(collection);
    if (isPending || error) return;
    setRequests(requests);
  }, [data, error, isPending]);

  useEffect(() => {
    if (requestUuid) {
      const selectedRequest = requests.find(
        (item) => item.uuid === requestUuid
      );
      if (selectedRequest) {
        setRequest(selectedRequest);
      }
    }
  }, [requestUuid, requests]);

  const handleSetRequest = (selectedRequest) => {
    setRequest(selectedRequest);

    // append the selectd uuid to the URL
    window.history.pushState(
      null,
      "",
      routes.auth.request.view(uuid, selectedRequest?.uuid)
    );
  };

  return (
    <>
      <Heading
        to={routes.auth.collections}
        back={t("Collection list")}
        title={t("Requests")}
        actionButtons={<CollectionNav uuid={uuid} />}
      />
      {error ? (
        <Error />
      ) : (
        <div>
          {requests?.length < 1 ? (
            <Empty collection={collection} />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <SearchBox itemFilter="name" data={requests} onSearch={{}} />
                <div className="action__buttons">
                  {requests?.length > 0 && <ModalClear collectionUuid={uuid} />}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4">
                  {requests.map((item) => (
                    <div
                      key={item?.uuid}
                      className={`request__item mb-2 card p-3 ${
                        item?.uuid === request?.uuid
                          ? "active"
                          : "cursor-pointer"
                      }`}
                      onClick={() => handleSetRequest(item)}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center gap-1 bg-light rounded-1 p-1 text-truncate">
                          <div
                            className={`request rounded-1 px-2 py-0 small method__${item.method}`}
                          >
                            {item.method}
                          </div>
                          <div className="font-monospace text-truncate">
                            {item.path}
                          </div>
                        </div>
                      </div>
                      <div className="button__actions d-flex justify-content-between align-items-center mt-2">
                        <div className="created text-muted">
                          {item.matched ? (
                            <span className="badge matcher bg-success fw-normal bg-opacity-25 text-success rounded-1">
                              {t("Matched")}
                            </span>
                          ) : (
                            <span className="badge matcher bg-danger fw-normal bg-opacity-25 text-danger rounded-1">
                              {t("Not matched")}
                            </span>
                          )}
                        </div>
                        <div className="created text-muted">
                          <small>{item.createdAt}</small>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-lg-8">
                  {request?.uuid && (
                    <Accordion
                      flush
                      defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                      alwaysOpen
                    >
                      <h4 className="mt-1 mb-4">
                        {t("Request @")} {request.createdAt}
                      </h4>
                      <div className="request__details card p-3 mb-4">
                        <h5 className="mb-3">{t("Request")}</h5>
                        <div className="bg-light py-1 px-2 rounded mb-4">
                          <table className="table table-striped">
                            <thead className="visually-hidden">
                              <tr>
                                <th scope="col">Key</th>
                                <th scope="col">Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row" className="fw-semibold">
                                  {t("Path")}
                                </th>
                                <td className="text-break">{request.path}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="fw-semibold">
                                  {t("Absolute URL")}
                                </th>
                                <td className="text-break">
                                  {apiDomain(collection.domain, request.path)}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="fw-semibold">
                                  {t("Method")}
                                </th>
                                <td colSpan="2">{request.method}</td>
                              </tr>
                              <tr>
                                <th scope="row" className="fw-semibold">
                                  {t("Client IP")}
                                </th>
                                <td colSpan="2">{request.ip}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {!isObjectEmpty(request.parameters) && (
                          <Accordion.Item eventKey="0" className="mb-4">
                            <h6 className="ms-1">{t("Parameters")}</h6>
                            <div className="bg-light py-1 px-2 rounded">
                              <table className="table table-striped">
                                <thead className="visually-hidden">
                                  <tr>
                                    <th scope="col">Key</th>
                                    <th scope="col">Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(request.parameters).map(
                                    ([key, value]) => (
                                      <tr key={key}>
                                        <th scope="row" className="fw-semibold">
                                          {key}
                                        </th>
                                        <td colSpan="2" className="text-break">
                                          {value}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Accordion.Item>
                        )}

                        {!isObjectEmpty(request.headers) && (
                          <Accordion.Item eventKey="1" className="mb-4">
                            <Accordion.Header>{t("Headers")}</Accordion.Header>
                            <Accordion.Body className="bg-light py-1 px-2 rounded">
                              <table className="table table-striped">
                                <thead className="visually-hidden">
                                  <tr>
                                    <th scope="col">Header</th>
                                    <th scope="col">Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(request.headers).map(
                                    ([key, value]) => (
                                      <tr key={key}>
                                        <th scope="row" className="fw-semibold">
                                          {key}
                                        </th>
                                        <td colSpan="2" className="text-break">
                                          {value}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}

                        {request.request_body && (
                          <Accordion.Item eventKey="2" className="mb-0">
                            <Accordion.Header>
                              {t("Request body")}
                            </Accordion.Header>
                            <Accordion.Body className="bg-light p-3 rounded">
                              <pre className="text-body">
                                <code>{request.request_body}</code>
                              </pre>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </div>
                      <div className="response__details card p-3">
                        <h5 className="mb-3">{t("Response")}</h5>
                        <div className="bg-light py-1 px-2 rounded mb-4">
                          <table className="table table-striped">
                            <thead className="visually-hidden">
                              <tr>
                                <th scope="col">Key</th>
                                <th scope="col">Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {request?.endpoint && (
                                <tr>
                                  <th scope="row" className="fw-semibold">
                                    {t("Matched endpoint")}
                                  </th>
                                  <td colSpan="2" className="text-break">
                                    <Link
                                      to={routes.auth.endpoint.edit(
                                        request?.endpoint?.uuid
                                      )}
                                    >
                                      <span>{request.endpoint.name}</span>
                                      <i className="ti ti-angle-right fs-5 ms-2"></i>
                                    </Link>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <th scope="row" className="fw-semibold">
                                  {t("Status")}
                                </th>
                                <td colSpan="2" className="text-break">
                                  <StatusBadge status={request.code} />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row" className="fw-semibold">
                                  {t("Content type")}
                                </th>
                                <td colSpan="2" className="text-break">
                                  {request.type}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        {request?.endpoint?.headers && (
                          <Accordion.Item eventKey="4" className="mb-4">
                            <Accordion.Header>{t("Headers")}</Accordion.Header>
                            <Accordion.Body className="bg-light py-1 px-2 rounded">
                              <table className="table table-striped">
                                <thead className="visually-hidden">
                                  <tr>
                                    <th scope="col">Key</th>
                                    <th scope="col">Value</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Object.entries(
                                    request?.endpoint.headers
                                  ).map(([key, value]) => (
                                    <tr key={key}>
                                      <th scope="row" className="fw-semibold">
                                        {key}
                                      </th>
                                      <td colSpan="2" className="text-break">
                                        {value}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                        {request.response_body && (
                          <Accordion.Item eventKey="5" className="mb-0">
                            <Accordion.Header>
                              {t("Response body")}
                            </Accordion.Header>
                            <Accordion.Body className="bg-light p-3 rounded">
                              <pre>{request.response_body}</pre>
                            </Accordion.Body>
                          </Accordion.Item>
                        )}
                      </div>
                    </Accordion>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RequestList;
