import { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import "./Forms.scss";

export default function Textarea({
  id,
  label,
  defaultValue,
  onChange,
  details,
  defaultLanguage,
  ...props
}) {
  const [value, setValue] = useState(defaultValue);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    // Reset error message when language changes
    setErrorMessage(null);
  }, [defaultLanguage]);

  const handleChange = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleEditorValidation = (markers) => {
    if (markers.length === 0) {
      setErrorMessage(null);
      return;
    }
    const { message } = markers[0];
    const line = markers[0].startLineNumber;

    setErrorMessage(`Line ${line}: ${message}`);
  };

  return (
    <div className="mb-3">
      <label htmlFor={`${id}-editor`} className="form-label">
        {label}
      </label>
      <div role="textbox" className="monaco-editor-container">
        <Editor
          key={`${id}-editor`}
          onChange={handleChange}
          height="20vh"
          value={value}
          language={defaultLanguage}
          defaultValue={defaultValue}
          className="form-control px-0 py-3"
          onValidate={handleEditorValidation}
          options={{
            minimap: { enabled: false },
            selectionHighlight: false,
            scrollBeyondLastLine: false,
            renderLineHighlight: "none",
            // Add these options to help with accessibility
            ariaLabel: label || "Code editor",
            tabIndex: 0,
          }}
          {...props}
        />
      </div>
      {details && <div className="form-text small">{details}</div>}
      {errorMessage && (
        <div className="form-text text-danger small">{errorMessage}</div>
      )}
    </div>
  );
}
