import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreate } from "../../../services/endpointService";
import Form from "../Form/Form";
import Button from "../../Button/Button";
import Heading from "../../Heading/Heading";
import routes from "../../../configs/routes";
import Alert from "../../Alert/Alert";
import { parseError } from "../../../utils/helpers";
import Import from "./Import";

const Create = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { collectionUuid } = useParams();
  const [formData, setFormData] = useState({});
  const [formInitialData, setFormInitialData] = useState({});

  const { data, mutate: create, error, isSuccess, isPending } = useCreate();

  const handleOnFormChange = useCallback((newFormData) => {
    setFormData((currentData) => ({
      ...currentData,
      ...newFormData,
    }));
  }, []);

  const handleImportAction = useCallback(
    (importedData) => {
      // Validate and transform imported data if necessary
      const sanitizedData = {
        ...formData, // Keep default values as fallback
        ...importedData,
        // Ensure required fields have valid values
        name: importedData.name || importedData.path,
        method: importedData.method || "GET",
        code: importedData.code || 200,
        type: importedData.type || "json",
        // Ensure objects are properly initialized
        headers: importedData.headers || {},
        parameters: importedData.parameters || null,
      };
      setFormInitialData(sanitizedData);
    },
    [formData]
  );

  const handleCreateAction = () => {
    create({
      endpoint: formData,
      uuid: collectionUuid,
    });
  };

  useEffect(() => {
    if (isSuccess && data) {
      navigate(routes.auth.endpoints(collectionUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  return (
    <>
      <Heading
        back={t("Endpoints")}
        to={routes.auth.endpoints(collectionUuid)}
        title={t("Create new endpoint")}
        actionButtons={<Import onImport={handleImportAction} />}
      />
      {error && (
        <Alert variant="danger" title={t("Oopss")}>
          {t(parseError(error).message)}
        </Alert>
      )}
      <Form endpointData={formInitialData} formContent={handleOnFormChange} />
      <div className="sub-container d-flex justify-content-end mt-4">
        <Button
          variant="primary"
          onClick={handleCreateAction}
          isDisabled={!formData?.name}
          isLoading={isPending}
        >
          <i className="ti ti-square-check fs-4"></i>
          <span>{t("Create endpoint")}</span>
        </Button>
      </div>
    </>
  );
};

export default Create;
