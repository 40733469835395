const CurlDisplay = ({ endpoint, collection }) => {
  // Helper function to wrap quoted content with colored spans
  const colorizeQuotedContent = (text) => {
    return text.split('"').map((part, index) => {
      // If it's an even index, it's outside quotes
      if (index % 2 === 0) {
        return (
          <span key={index} className="text">
            {part}
          </span>
        );
      }
      // If it's an odd index, it's inside quotes (including quotes)
      return (
        <span key={index} className="text-success">
          "{part}"
        </span>
      );
    });
  };

  const buildCurlCommand = () => {
    const parts = [];

    // Base curl command with method and URL on the same line
    const url = `https://${collection?.domain}.castapi.cloud${endpoint?.path}`;
    const baseCommand = ["curl"];
    if (endpoint?.method && endpoint.method !== "GET") {
      baseCommand.push(`-X ${endpoint.method}`);
    }
    baseCommand.push(`"${url}"`);
    parts.push([baseCommand.join(" ")]);

    if (endpoint?.headers) {
      Object.entries(endpoint.headers).forEach(([key, value]) => {
        parts.push([`-H "${key}: ${value}"`, true]);
      });
    }

    if (endpoint?.body && ["POST", "PUT", "PATCH"].includes(endpoint?.method)) {
      parts.push([`-d '${JSON.stringify(endpoint.body)}'`, true]);
    }

    return parts;
  };

  return (
    <div className="code-window">
      <div className="window-head">
        <div className="dots">
          <div className="dot bg-danger"></div>
          <div className="dot bg-warning"></div>
          <div className="dot bg-success"></div>
        </div>
        <div className="tab">cURL</div>
      </div>
      <div className="window-body">
        {endpoint.path ? (
          <>
            <div className="code-content">
              {buildCurlCommand().map(([part, hasLineBreak], index) => (
                <div key={index} className="line d-flex flex-row">
                  <div key={index} className="code-content-line">
                    {colorizeQuotedContent(part)}
                    {(index === 0 ||
                      (hasLineBreak &&
                        index !== buildCurlCommand().length - 1)) && (
                      <span className="text-primary"> \</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="spinner-grow text-primary m-auto" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CurlDisplay;
