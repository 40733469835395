import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import routes from "../configs/routes";

const AuthLayout = () => {
  const { session } = useAuth();

  if (session && session?.token) {
    // User is authenticated, redirect to home page
    return <Navigate to={routes.auth.home} />;
  }

  return (
    <div className="d-flex align-items-center min-h-100">
      <main className="auth flex-grow-1">
        <Outlet />
      </main>
    </div>
  );
};
export default AuthLayout;
