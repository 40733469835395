import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { debounce } from "lodash";

const InputFormData = ({ onChange, defaultData, type }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState([]);
  const [viewExpanded, setViewExpanded] = useState(false);

  useEffect(() => {
    let parsedData = defaultData;

    if (typeof defaultData === "string") {
      try {
        parsedData = JSON.parse(defaultData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return;
      }
    }

    if (
      parsedData &&
      typeof parsedData === "object" &&
      Object.keys(parsedData).length > 0
    ) {
      const transformedData = Object.entries(parsedData).map(([key, value]) => {
        return {
          label: key,
          value:
            typeof value === "string" ? value.replace(/^"|"$/g, "") : value,
        };
      });
      setFormData(transformedData);
    }
  }, [defaultData]);

  const updateParent = useCallback(
    (updatedFormData) => {
      const formattedData = updatedFormData.reduce((acc, { label, value }) => {
        acc[label] = value;
        return acc;
      }, {});
      onChange(formattedData);
    },
    [onChange]
  );

  const debouncedUpdateParent = useMemo(
    () => debounce(updateParent, 300),
    [updateParent]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateParent.cancel();
    };
  }, [debouncedUpdateParent]);

  const addHeader = useCallback(() => {
    setFormData((prev) => [...prev, { label: "", value: "" }]);
  }, []);

  const removeHeader = useCallback(
    (indexToRemove) => {
      setFormData((prev) => {
        const newFormData = prev.filter((_, index) => index !== indexToRemove);
        debouncedUpdateParent(newFormData);
        return newFormData;
      });
    },
    [debouncedUpdateParent]
  );

  const updateHeader = useCallback(
    (index, newLabel, newValue) => {
      setFormData((prev) => {
        const newFormData = prev.map((header, i) =>
          i === index ? { label: newLabel, value: newValue } : header
        );
        debouncedUpdateParent(newFormData);
        return newFormData;
      });
    },
    [debouncedUpdateParent]
  );

  return (
    <div className="multi-field mb-4">
      <div className="d-flex justify-content-between align-items-center">
        <label htmlFor="form-data" className="form-label mb-0">
          <span>
            {type === "form-data" ? t("Form Data") : t("Form URL Encoded")}
          </span>
        </label>
        <Button
          variant="transparent"
          className="px-2 py-1 me-5"
          onClick={addHeader}
        >
          {t("+ Add row")}
        </Button>
      </div>
      <div className={viewExpanded ? "full-list" : "resumed"}>
        {formData.map((form, index) => (
          <div
            key={`form-${index}`}
            className="mt-2 d-flex gap-2 align-items-center"
          >
            <div className="w-50">
              <Form.Control
                type="text"
                id={`form-key-${index}`}
                placeholder="Key"
                value={form.label}
                onChange={(e) =>
                  updateHeader(index, e.target.value, form.value)
                }
              />
            </div>
            <div>:</div>
            <div className="w-100">
              <Form.Control
                type="text"
                id={`form-value-${index}`}
                placeholder="Value"
                value={form.value}
                onChange={(e) =>
                  updateHeader(index, form.label, e.target.value)
                }
              />
            </div>
            <Button
              variant="transparent"
              className="d-flex align-items-center icon p-2"
              onClick={() => removeHeader(index)}
            >
              <i className="ti ti-trash fs-4 text-danger" />
            </Button>
          </div>
        ))}
      </div>
      {formData.length > 5 && (
        <div
          className={`mt-2 text-center ${
            viewExpanded ? "expanded" : "shadow-bottom"
          }`}
        >
          <button
            className="btn btn-transparent px-2 py-1 btn-sm"
            onClick={() => setViewExpanded((prev) => !prev)}
          >
            {viewExpanded ? t("Collapse") : t("Expand")}
          </button>
        </div>
      )}
    </div>
  );
};

export default InputFormData;
