import { Link } from "react-router-dom";
import "./Button.scss";

const ButtonLink = ({
  children,
  href,
  variant,
  size,
  isLoading,
  isDisabled,
  ...rest
}) => {
  return (
    <Link
      className={`btn btn-${variant || "primary"} btn-${size || "md"} ${
        isDisabled || isLoading ? "disabled" : "target"
      }`}
      to={href}
      {...rest}
    >
      <div className="d-flex align-items-center gap-1">
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm me-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {children}
      </div>
    </Link>
  );
};

export default ButtonLink;
