import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useFetch = () => {
  return useQuery({
    queryKey: ["workspaces"],
    queryFn: () => fetchWithErrorHandling("/workspace/list"),
  });
};

export const useFetchItem = (uuid) => {
  return useQuery({
    queryKey: ["workspace", uuid],
    queryFn: () => fetchWithErrorHandling(`/workspace/${uuid}`),
    enabled: !!uuid,
  });
};

export const useUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ uuid, workspace }) =>
      fetchWithErrorHandling(`/workspace/${uuid}/update`, {
        method: "PUT",
        body: JSON.stringify(workspace),
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["workspace", variables.uuid],
      });

      queryClient.invalidateQueries({
        queryKey: ["workspaces"],
      });
    },
  });
};
