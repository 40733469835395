import { useTranslation } from "react-i18next";

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className="my-5 mx-auto text-center col-sm-12 col-md-9 col-xl-6 col-xxl-5">
      <h4 className="mb-3">😩 {t("Noo! Something went wrong!")}</h4>
      <p className="mb-0">
        {t("We couldn't load your requests right now.")}
      </p>
      <p className="mb-3">
        {t("Try refreshing the page or check your connection.")} 🔄
      </p>
      <p className="mb-0">
        {t("If the problem persists, our team is on it!")} 🛠️
      </p>
    </div>
  );
};

export default Error;
