import ButtonLink from "../Button/ButtonLink";

const Error = ({ title, description, target, button }) => {
  return (
    <div className="col-lg-6 m-auto my-5">
      <div className="d-flex justify-content-center">
        <i className="ti ti-alert-triangle fs-1 text-danger"></i>
      </div>
      <div className="text-center mt-3">
        <h4 className="mb-3">{title ?? "Something went wrong"}</h4>
        <p className="lead">{description}</p>
        <div className="my-4">
          <ButtonLink href={target} variant="secondary">
            <i className="ti ti-arrow-left me-2"></i>
            <span>{button}</span>
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default Error;
