import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useFetchList = (uuid) =>
  useQuery({
    queryKey: ["requests"],
    queryFn: () => fetchWithErrorHandling(`/request/collection/${uuid}/list`),
    //refetchInterval: 10000, // Refetch every 10 second
  });

export const useDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uuid) =>
      fetchWithErrorHandling(`/request/collection/${uuid}/delete`, {
        method: "DELETE",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
    },
  });
};
