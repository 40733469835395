import { Outlet } from "react-router-dom";

const OAuthLayout = () => {
  return (
    <div className="d-flex align-items-center min-h-100">
      <main className="auth flex-grow-1">
        <Outlet />
      </main>
    </div>
  );
};
export default OAuthLayout;
