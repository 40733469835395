import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EndpointContext } from "../../../contexts/EndpointContext";
import { useUpdate, useDuplicate } from "../../../services/endpointService";
import routes from "../../../configs/routes";
import Form from "../Form/Form";
import Button from "../../Button/Button";
import Heading from "../../Heading/Heading";
import Alert from "../../Alert/Alert";
import DeleteAction from "../Item/Delete/ButtonDelete";
import { formateDate } from "../../../utils/helpers";

const Create = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { endpoint, isPending, error } = useContext(EndpointContext);
  const [formData, setFormData] = useState({});
  const [endpointData, setEndpointData] = useState({});

  const {
    mutate: update,
    error: updateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdatePending,
  } = useUpdate();

  const {
    data: duplicateData,
    mutate: duplicate,
    error: duplicateError,
    isSuccess: isDuplicateSuccess,
    isPending: isDuplicatePending,
  } = useDuplicate();

  useEffect(() => {
    if (!endpoint) return;
    setEndpointData(endpoint);
  }, [endpoint, error, isPending]);

  useEffect(() => {
    if (isDuplicateSuccess) {
      const { content } = duplicateData;
      navigate(routes.auth.endpoint.edit(content.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDuplicateSuccess]);

  const handleOnFormChange = (formData) => {
    setFormData(formData);
  };

  const handleCreateAction = () => {
    update({
      endpoint: formData,
      uuid: endpoint.uuid,
    });
  };

  const handleDuplicateAction = () => {
    duplicate({
      endpoint: formData,
      uuid: endpoint.uuid,
    });
  };

  return (
    <>
      <Heading
        back={t("Endpoints")}
        to={routes.auth.endpoints(endpointData.collection_uuid)}
        title={t("Edit endpoint")}
        actionButtons={
          <div className="d-flex gap-2">
            <Button
              variant="white"
              onClick={handleDuplicateAction}
              isDisabled={isDuplicatePending}
              isLoading={isDuplicatePending}
            >
              <i className="ti ti-copy fs-5"></i>
              <span>{t("Duplicate")}</span>
            </Button>
          </div>
        }
      />

      {updateError && (
        <Alert variant="danger" title={t("Oopss")}>
          {updateError.message}
        </Alert>
      )}

      {isUpdateSuccess && (
        <Alert variant="success" title={t("Success")}>
          <span>{t("Endpoint updated successfully.")}</span>
        </Alert>
      )}

      {duplicateError && (
        <Alert variant="danger" title={t("Oopss")}>
          {duplicateError.message}
        </Alert>
      )}

      {isDuplicateSuccess && (
        <Alert variant="success" title={t("Success")}>
          <span>{t("Endpoint duplicated successfully.")}</span>
        </Alert>
      )}

      <div className="sub-container">
        <Form endpointData={endpointData} formContent={handleOnFormChange} />
        <div className="mt-4 d-flex justify-content-end">
          <Button
            variant="primary"
            onClick={handleCreateAction}
            isDisabled={!formData.name || isUpdatePending}
            isLoading={isUpdatePending}
          >
            <i className="ti ti-device-floppy fs-4"></i>
            <span>{t("Save changes")}</span>
          </Button>
        </div>

        {endpointData && (
          <div className="card p-4 mt-4">
            <div className="d-flex align-items-center gap-1 mb-4">
              <i className="ti ti-alert-triangle fs-4 text-danger" />
              <h3 className="fs-5 mb-0">{t("Danger zone")}</h3>
            </div>
            <div className="mb-3">
              <DeleteAction endpointUuid={endpointData.uuid} />
            </div>
            <p className="mb-0 small">
              {t(
                "This action will delete all requests associated with this endpoint."
              )}
            </p>
          </div>
        )}

        {endpointData && (
          <div className="d-flex justify-content-between align-items-center mt-2">
            <div className="d-flex align-items-center">
              <strong className="fw-semibold">{t("Created by:")}</strong>
              <div className="ms-1">
                <p className="mb-0">
                  {endpointData?.user?.first_name}{" "}
                  {endpointData?.user?.last_name}
                </p>
                <p className="mb-0 small">{endpointData?.user?.email}</p>
              </div>
            </div>
            <div className="text-end">
              <p className="mb-0">
                <strong className="fw-semibold">{t("Created at:")}</strong>
                <span className="ms-1">
                  {formateDate(endpointData?.createdAt)}
                </span>
              </p>
              <p className="mb-0">
                <strong className="fw-semibold">{t("Updated at:")}</strong>
                <span className="ms-1">
                  {formateDate(endpointData?.updatedAt)}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Create;
