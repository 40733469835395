import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchWidgets } from "../../../services/statsService";

const Stats = ({ account }) => {
  const { t } = useTranslation();
  const { data } = useFetchWidgets(account);
  const [widgets, setWidgets] = useState(null);

  useEffect(() => {
    if (data) {
      const { widgets } = data;
      setWidgets(widgets);
    }
  }, [data]);

  return (
    <div className="row mt-3">
      <div className="col-lg-3">
        <div className="card usage-card p-3 pt-4 text-center">
          <div className="d-flex justify-content-center">
            <i className="icon fs-3 p-2 text-white ti ti-library bg-danger"></i>
          </div>
          <div className="mt-2 usage">
            <h5 className="mb-0">{t("Collections")}</h5>
            <small className="text-muted">{t("Workspace collections")}</small>
            <div className="mt-2 d-flex align-items-center justify-content-center">
              {widgets ? (
                <>
                  <h3 className="mb-0">{widgets.collections.total}</h3>
                  <h4 className="mb-0 text-muted">
                    /{widgets.collections.limit}
                  </h4>
                </>
              ) : (
                <div
                  className="placeholder-wave"
                  style={{ width: "60px", height: "29.4px" }}
                >
                  <div className="placeholder rounded w-100 h-100"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="card usage-card p-3 pt-4 text-center">
          <div className="d-flex justify-content-center">
            <i className="icon fs-3 p-2 text-white ti ti-api bg-warning"></i>
          </div>
          <div className="mt-2 usage">
            <h5 className="mb-0">{t("API requests")}</h5>
            <small className="text-muted">{t("Monthly API requests")}</small>
            <div className="mt-2 d-flex align-items-center justify-content-center">
              {widgets ? (
                <>
                  <h3 className="mb-0">{widgets.requests.total}</h3>
                  <h4 className="mb-0 text-muted">/{widgets.requests.limit}</h4>
                </>
              ) : (
                <div
                  className="placeholder-wave"
                  style={{ width: "100px", height: "29.4px" }}
                >
                  <div className="placeholder rounded w-100 h-100"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3">
        <div className="card usage-card p-3 pt-4 text-center">
          <div className="d-flex justify-content-center">
            <i className="icon fs-3 p-2 text-white ti ti-users bg-info"></i>
          </div>
          <div className="mt-2 usage">
            <h5 className="mb-0">{t("Users/Team")}</h5>
            <small className="text-muted">Including you</small>
            <div className="mt-2 d-flex align-items-center justify-content-center">
              {widgets ? (
                <>
                  <h3 className="mb-0">{widgets.users.total}</h3>
                  <h4 className="mb-0 text-muted">/{widgets.users.limit}</h4>
                </>
              ) : (
                <div
                  className="placeholder-wave me-3"
                  style={{ width: "60px", height: "29.4px" }}
                >
                  <div className="placeholder rounded w-100 h-100"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
