import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tooltip as BootstrapTooltip } from "react-bootstrap";
import "./Tooltip.scss";

const Tooltip = React.forwardRef(
  ({ id, children, message, placement }, ref) => (
    <OverlayTrigger
      placement={placement ?? "top"}
      overlay={<BootstrapTooltip id={id}>{message}</BootstrapTooltip>}
    >
      {React.cloneElement(children, { ref })}
    </OverlayTrigger>
  )
);

export default Tooltip;
