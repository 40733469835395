import { useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import "./CopyToClipboard.scss";

const CopyToClipboard = ({ label, value, text }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value).then(
      () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 5000);
      },
      (err) => setCopySuccess(false)
    );
  };

  return (
    <div className="copy-to-clipboard d-flex align-items-center">
      <span>{label ?? value}</span>
      <button
        className="btn btn-icon"
        type="button"
        onClick={copyToClipboard}
        aria-label={copySuccess ? "Copied" : "Copy to clipboard"}
      >
        <Tooltip
          message={copySuccess ? "Copied!" : text ? text : "Copy"}
          placement="right"
        >
          {copySuccess ? (
            <i className="copy-icon ti ti-copy-check text-success fs-4"></i>
          ) : (
            <i className="copy-icon ti ti-copy text-primary fs-4"></i>
          )}
        </Tooltip>
      </button>
    </div>
  );
};

export default CopyToClipboard;
