import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "../../Button/Button";
import { useDelete } from "../../../services/requestService";

const ModalClear = ({ collectionUuid }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { mutate: clearAll, error, isSuccess, isPending } = useDelete();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClearAll = async () => {
    clearAll(collectionUuid);

    if (isSuccess) {
      handleClose();
    }
    handleClose();
  };

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="ti ti-recycle fs-5"></i>
        <span>{t("Clear")}</span>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header
          closeButton
          className="d-flex justify-content-between align-items-center border-0"
        ></Modal.Header>
        <Modal.Body className="text-center col-lg-6 m-auto my-4">
          <i className="ti ti-recycle fs-1 text-danger"></i>
          <h5 className="my-3">{t("Clear requests")}</h5>
          <p>
            {t(
              "Are you sure you want to delete all requests from this collection?"
            )}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          {error && (
            <div className="text-danger" role="alert">
              {t("An error occurred while clearing requests.")}
            </div>
          )}
          <Button variant="white" onClick={handleClose}>
            <span>{t("No, cancel")}</span>
          </Button>
          <Button
            variant="danger"
            isLoading={isPending}
            isDisabled={isPending}
            onClick={handleClearAll}
          >
            <span>{t("Yes, clear all")}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalClear;
