import { useState, useEffect } from "react";
import "./Forms.scss";

export default function InputText({
  id,
  label,
  defaultValue,
  onChange,
  details,
  isValid,
  validationMessages,
  ...rest
}) {
  const [value, setValue] = useState("");
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (defaultValue === undefined || defaultValue === null || !defaultValue) {
      setValue("");
    } else {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    // Check if the input value is empty
    if (typeof isValid === "boolean") {
      setValidation(isValid ? "valid" : "invalid");
    }
  }, [isValid, id, value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue);

    // Disable validation if the input is empty
    if (newValue === "") {
      setValidation(null);
    }
  };

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        <span>{label}</span>
        {rest.required && <span className="text-danger">*</span>}
      </label>
      <input
        type="text"
        id={id}
        value={value}
        onChange={handleChange}
        className={`form-control ${
          validation ? `is-${validation}` : "no-validation"
        }`}
        {...rest}
      />
      {details && !validation && (
        <div className="form-text small">{details}</div>
      )}
      {validation && validationMessages && (
        <div className={`form-text small ${validation}-feedback`}>
          {validationMessages[validation]}
        </div>
      )}
    </div>
  );
}
