import React from "react";

const Input = ({ label, name, register, errors, type = "text", ...rest }) => {
  return (
    <div className="mb-4">
      <label htmlFor={name} className="form-label">
        <span>{label}</span>
        {rest.required && <span className="text-danger">*</span>}
      </label>
      <input
        type={type}
        id={name}
        {...register(name)}
        className={`form-control ${errors[name] ? "is-invalid" : ""}`}
        {...rest}
      />
      {errors[name] && (
        <div className="position-absolute invalid-feedback ms-1">
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default Input;
