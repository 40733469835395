import { useEffect, useState } from "react";
import ButtonLink from "../../Button/ButtonLink";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../configs/routes";

const Empty = ({ uuid }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isFlash, setIsFlash] = useState(false);

  useEffect(() => {
    // Extract the message from URL parameters
    const params = new URLSearchParams(location.search);
    const onboard = params.get("onboard");

    if (onboard) {
      setIsFlash(true);

      // Remove the message parameter from the URL
      params.delete("onboard");
      navigate(
        { pathname: location.pathname, search: params.toString() },
        { replace: true }
      );
    }
  }, [location, navigate]);

  return (
    <div className="text-center col-md-6 my-5 mx-auto">
      {isFlash && (
        <div className="mb-3">
          <h3 className="fw-semibold">
            <span>Your collection has been created!</span>
            <span> 🎉</span>
          </h3>
        </div>
      )}

      <h4 className="mb-3">{t("No endpoints in this collection yet")}</h4>
      <p className="lead">
        <span>🚀 </span>
        <span>{t("Create an endpoint to start performing requests!")}</span>
      </p>
      <div className="my-4 mx-auto">
        <ButtonLink href={routes.auth.endpoint.create(uuid)} size={"lg"}>
          <i className="ti ti-square-rounded-plus fs-4"></i>
          <span>{t("Create an endpoint")}</span>
        </ButtonLink>
      </div>
    </div>
  );
};

export default Empty;
