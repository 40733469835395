import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFetchList } from "../../../services/endpointService";
import routes from "../../../configs/routes";
import CurlDisplay from "../../Display/CurlDisplay";

const Empty = ({ collection }) => {
  const { t } = useTranslation();
  const { data, isPending } = useFetchList(collection?.uuid);
  const [endpoint, setEndpoint] = useState([]);

  useEffect(() => {
    if (!data || isPending) return;
    const { content: collection = {} } = data || {};
    const { endpoints = [] } = collection || {};

    if (endpoints.length > 0) {
      setEndpoint(endpoints[Math.floor(Math.random() * endpoints.length)]);
    }
  }, [data, isPending]);

  return (
    <div className="my-5 mx-auto text-center col-sm-12 col-md-9 col-xl-8 col-xxl-8">
      <h4 className="mb-3">🤷‍♂️ {t("No requests here")}</h4>
      <p className="mb-1 lead">
        {t("It looks like you haven't made any requests yet.")}
      </p>
      {endpoint.uuid ? (
        <>
          <p>
            {t("You can make a request by using the following cURL command.")}
          </p>
          <CurlDisplay collection={collection} endpoint={endpoint} />
          <div className="my-4">
            <Link
              to={routes.auth.endpoint.create(collection?.uuid)}
              className="btn btn-light"
            >
              {t("Create an endpoint")}
            </Link>
            <span className="small mx-2">{t("or")}</span>
            <Link
              to={routes.auth.endpoint.edit(endpoint?.uuid)}
              className="small"
            >
              {t("edit endpoint showing in cURL")}
            </Link>
          </div>
        </>
      ) : (
        <div className="mt-4">
          <Link
            to={routes.auth.endpoint.create(collection?.uuid)}
            className="btn btn-light"
          >
            <div className="d-flex align-items-center gap-1">
              <i className="ti ti-square-rounded-plus fs-4"></i>
              <span>{t("Create an endpoint")}</span>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Empty;
