import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { isTokenValid } from "../utils/token";
import routes from "../configs/routes";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useLocalStorage("sessionCastAPI", null);
  const navigate = useNavigate();

  const login = (token) => {
    if (!token && !isTokenValid(token)) {
      navigate(routes.public.login, { replace: true });
      return;
    }
    setSession({ token: token });

    // get redirect URL from localStorage
    const redirect = localStorage.getItem("redirectCastAPI");
    if (redirect) {
      localStorage.removeItem("redirectCastAPI");
      navigate(redirect);
      return;
    }
    navigate(routes.auth.home);
  };

  // Function to log out the user
  const logout = () => {
    setSession("");
    navigate(routes.public.login, { replace: true });
  };

  const value = useMemo(
    () => ({
      session,
      setSession,
      login,
      logout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
