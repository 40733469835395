import { useParams } from "react-router-dom";
import Create from "../../components/Collections/Views/Create";
import Edit from "../../components/Collections/Views/Edit";
import CollectionProvider from "../../providers/CollectionProvider";

const CollectionForm = () => {
  const { uuid } = useParams();

  return uuid ? (
    <CollectionProvider>
      <Edit />
    </CollectionProvider>
  ) : (
    <Create />
  );
};

export default CollectionForm;
