import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAccount } from "../../providers/AccountProvider";
import { useUpdateUser } from "../../services/userService";
import routes from "../../configs/routes";
import Heading from "../../components/Heading/Heading";
import Input from "../../components/Forms/Input";
import SettingsNavigation from "../../components/Navigation/SettingsNavigation";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import LanguageSelectbox from "../../components/Language/LanguageSelectbox";
import GitHubLogin from "../../components/Auth/GitHubLogin";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Provide your first name"),
  lastName: Yup.string().required("Provide your last name"),
  email: Yup.string()
    .email("It looks like your email is invalid")
    .required("An email address is required here"),
});

const SettingsAccount = () => {
  const { t } = useTranslation();
  const {
    mutate: update,
    error: updateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdatePending,
  } = useUpdateUser();
  const { account } = useAccount();

  // Add state to track the selected theme
  const [selectedTheme, setSelectedTheme] = useState("default");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const handleSaveAction = (formData) => {
    update({
      user: {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
      },
    });
  };

  // Handle theme change
  const handleThemeChange = (e) => {
    setSelectedTheme(e.target.value);
  };

  return (
    <>
      <Heading
        to={routes.auth.home}
        back={t("Home")}
        title={t("Account")}
        actionButtons={<SettingsNavigation />}
      />
      {updateError && (
        <Alert variant="danger" title="Opss!">
          <span>{t("Something went wrong. Please try again.")}</span>
        </Alert>
      )}
      {isUpdateSuccess && (
        <Alert variant="success" title="Updated">
          <span>{t("Your account has been updated successfully.")}</span>
        </Alert>
      )}
      <form className="sub-container" onSubmit={handleSubmit(handleSaveAction)}>
        <div className="card p-4">
          <div className="d-flex align-items-center gap-1 mb-4">
            <i className="ti ti-user fs-4 text-primary" />
            <h3 className="fs-5 mb-0">{t("About you")}</h3>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                label={t("First name")}
                name="firstName"
                register={register}
                errors={errors}
                autoComplete="given-name"
                defaultValue={account?.firstName}
              />
            </div>
            <div className="col-md-6">
              <Input
                label={t("Last name")}
                name="lastName"
                register={register}
                errors={errors}
                autoComplete="family-name"
                defaultValue={account?.lastName}
              />
            </div>
          </div>

          <Input
            label={t("Email address")}
            name="email"
            register={register}
            errors={errors}
            type="email"
            autoComplete="email"
            defaultValue={account?.email}
          />
        </div>
        <div className="card p-4 mt-4">
          <div className="d-flex align-items-center gap-1 mb-4">
            <i className="ti ti-palette fs-4 text-primary"></i>
            <h3 className="fs-5 mb-0">{t("Preferences")}</h3>
          </div>
          <div className="col-4">
            <LanguageSelectbox />
          </div>

          <h4 className="h6">{t("Theme")}</h4>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="radio"
              id="darkTheme"
              name="theme"
              value="dark"
              checked={selectedTheme === "dark"}
              onChange={handleThemeChange}
            />
            <label className="form-check-label ms-1" htmlFor="darkTheme">
              <i className="ti ti-moon me-1"></i>
              <span>{t("Dark")}</span>
            </label>
          </div>
          <div className="form-check mb-2">
            <input
              className="form-check-input"
              type="radio"
              id="defaultTheme"
              name="theme"
              value="default"
              checked={selectedTheme === "default"}
              onChange={handleThemeChange}
            />
            <label className="form-check-label ms-1" htmlFor="defaultTheme">
              <i className="ti ti-sun me-1"></i>
              <span>{t("Default (light mode)")}</span>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              id="systemTheme"
              name="theme"
              value="system"
              checked={selectedTheme === "system"}
              onChange={handleThemeChange}
            />
            <label className="form-check-label ms-1" htmlFor="systemTheme">
              <i className="ti ti-circle-half-2 me-1"></i>
              <span>{t("Auto (system default)")}</span>
            </label>
          </div>
        </div>
        <div className="save-button mt-4 d-flex justify-content-end">
          <Button
            type="submit"
            variant="primary"
            isLoading={isUpdatePending}
            isDisabled={isUpdatePending || !isValid}
          >
            <i className="ti ti-device-floppy fs-4"></i>
            <span>{t("Save changes")}</span>
          </Button>
        </div>
        <div className="card p-4 mt-4">
          <div className="d-flex align-items-center gap-1 mb-4">
            <i className="ti ti-link fs-4 text-primary"></i>
            <h3 className="fs-5 mb-0">{t("Connect accounts")}</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <i className="ti ti-brand-github fs-3 me-2"></i>
              <div>
                <div className="fw-semibold">{t("GitHub")}</div>
                <p className="text-muted mb-0">
                  {account?.connected?.github ? (
                    <span className="small">
                      <span className="me-1">{t("Connected to")}</span>
                      <Link
                        className="text-muted"
                        target="_blank"
                        rel="noreferrer"
                        to={`https://github.com/${account?.connected?.github.login}`}
                      >
                        @{account?.connected?.github.login}
                      </Link>
                      <span>.</span>
                    </span>
                  ) : (
                    t("Connect your GitHub account to enable more features.")
                  )}
                </p>
              </div>
            </div>
            <div className="position-relative ms-auto">
              <GitHubLogin
                buttonText={
                  !account?.connected?.github ? (
                    <span>{t("Connect")}</span>
                  ) : (
                    <>
                      <i className="ti ti-check fs-4"></i>
                      <span>{t("Connected")}</span>
                    </>
                  )
                }
                isDisabled={account?.connected?.github}
                redirect={routes.auth.settings.account}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SettingsAccount;
