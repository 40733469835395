import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useAccount } from "../../providers/AccountProvider";
import { useUpdate, useFetchItem } from "../../services/workspaceService";
import routes from "../../configs/routes";
import Heading from "../../components/Heading/Heading";
import Input from "../../components/Forms/Input";
import SettingsNavigation from "../../components/Navigation/SettingsNavigation";
import Button from "../../components/Button/Button";
import Alert from "../../components/Alert/Alert";
import Tooltip from "../../components/Tooltip/Tooltip";

const validationSchema = Yup.object().shape({
  workspace: Yup.string().required("Your workspace needs a name."),
});

const SettingsWorkspace = () => {
  const { t } = useTranslation();
  const { account, session } = useAccount();
  const { mutate: update, error, isPending, isSuccess } = useUpdate();
  const { data: workspaceData } = useFetchItem(session.workspaceUuid);
  const [showEdit, setShowEdit] = useState(false);
  const [workspace, setWorkspace] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (workspaceData) {
      const { content } = workspaceData;
      setWorkspace(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  useEffect(() => {
    if (isSuccess) {
      setShowEdit(false);
    }
  }, [isSuccess]);

  const handleSaveAction = (formData) => {
    update({
      workspace: {
        name: formData.workspace,
      },
      uuid: workspace.uuid,
    });
  };

  const toogleEdit = () => {
    setShowEdit(!showEdit);
  };

  return (
    <>
      <Heading
        to={routes.auth.home}
        back={t("Home")}
        title={t("Workspace overview")}
        actionButtons={<SettingsNavigation />}
      />
      {error && (
        <Alert variant="danger" title="Opss!">
          <span>{t("Something went wrong. Please try again.")}</span>
        </Alert>
      )}
      {isSuccess && (
        <Alert variant="success" title="Updated">
          <span>{t("Workspace has been updated successfully.")}</span>
        </Alert>
      )}
      <form className="sub-container" onSubmit={handleSubmit(handleSaveAction)}>
        <div className="ms-1">
          <h3 className="h5 mb-1">{t("About")}</h3>
          <p className="small mb-2">
            {t("Update your workspace information.")}
          </p>
        </div>

        <div className="card p-4">
          <div
            className={`align-items-center justify-content-between ${
              showEdit ? "d-none" : "d-flex"
            }`}
          >
            <div>
              <div className="form-label">{t("Workspace name")}</div>
              <p style={{ margin: "12px 13px 33px 17px" }}>{workspace?.name}</p>
            </div>
            <Tooltip message={t("Edit name")} placement="top">
              <button
                type="button"
                className="btn btn-transparent btn-icon icon d-flex align-items-center"
                onClick={toogleEdit}
              >
                <i className="ti ti-edit fs-4"></i>
              </button>
            </Tooltip>
          </div>
          <div
            className={`align-items-center justify-content-between ${
              !showEdit ? "d-none" : "d-flex"
            }`}
          >
            <div className="d-flex gap-3">
              <Input
                label={t("Workspace name")}
                name="workspace"
                register={register}
                errors={errors}
                autoComplete="organization"
                defaultValue={workspace?.name}
              />
              <div style={{ marginTop: "26px" }}>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isPending}
                  isDisabled={isPending || !isValid}
                >
                  <i className="ti ti-check me-2 fs-5"></i>
                  <span>{t("Update")}</span>
                </Button>
              </div>
            </div>
            <Tooltip message={t("Cancel")} placement="top">
              <button
                type="button"
                className="btn btn-icon d-flex align-items-center p-2 border-0"
                onClick={toogleEdit}
              >
                <i className="ti ti-square-rounded-x fs-4 text-danger"></i>
              </button>
            </Tooltip>
          </div>
          <div className="mt-3 bg-light p-3 rounded">
            <h6>{t("Subscription")}</h6>
            <p className="mb-1">
              <span>Your workspace is currently subscribed to</span>
              <span className="ms-1 fw-semibold">Free</span>.
            </p>
            <p>
              Subscribe to one of the our plans to get access to all features
              and benefits.
            </p>
            <div className="position-relative d-inline-block">
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger z-3">
                Soon
              </span>
              <Link
                to={routes.auth.settings.billing}
                className="btn btn-secondary disabled"
                disabled
              >
                {t("Upgrade workspace")}
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-4 ms-1">
          <h3 className="h5 mb-1">{t("Team")}</h3>
          <p className="small mb-2">
            {t(
              "Invite your team members to collaborate on your workspace. You can manage their permissions."
            )}
          </p>
        </div>
        <div className="card p-4">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="form-label">{t("Members")}</div>
              <small className="text-muted">{t("Total of 1 member")}</small>
            </div>
            <div className="position-relative d-inline-block">
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger z-3">
                Soon
              </span>
              <Link
                to={routes.auth.settings.workspace}
                className="btn btn-primary disabled"
                disabled
              >
                {t("Invite members")}
              </Link>
            </div>
          </div>

          <div className="card p-2 mt-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <div className="icon avatar bg-secondary d-flex align-items-center justify-content-center">
                  <div className="text-light">{account.initials}</div>
                </div>
                <div>
                  <h6 className="mb-0">{account.fullname}</h6>
                  <small className="text-muted">{account.email}</small>
                </div>
              </div>
              <div className="role d-flex align-items-center">
                {account.uuid === workspace.owner_uuid ? (
                  <div
                    className="d-flex gap-1 align-items-center bg-success rounded py-1 px-2"
                    style={{ "--bs-bg-opacity": 0.1 }}
                  >
                    <small className="text-success">Owner</small>
                  </div>
                ) : (
                  <div className="d-flex gap-1 align-items-center bg-light rounded py-1 px-2">
                    <small className="text-muted">Member</small>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SettingsWorkspace;
