const authRoute = {
  home: `/`,
  collections: `/collections`,
  collection: {
    create: `/collection/create`,
    edit: (uuid) => `/collection/${uuid}/edit`,
  },
  endpoints: (uuid) => `/collection/${uuid}/endpoints`,
  endpoint: {
    create: (collectionUuid) => `/collection/${collectionUuid}/endpoint/create`,
    edit: (uuid) => `/endpoint/${uuid}/edit`,
  },
  requests: (uuid) => `/collection/${uuid}/requests`,
  request: {
    view: (uuid, request) => `/collection/${uuid}/request/${request}`,
  },
  settings: {
    account: `/settings/account`,
    security: `/settings/security`,
    billing: `/settings/billing`,
    workspace: `/settings/workspace`,
  },
};

const publicRoute = {
  login: "/auth/login",
  signup: "/auth/signup",
  github: "/auth/callback/github",
  landing: "/",
};

const routes = {
  auth: authRoute,
  public: publicRoute,
};

export default routes;
