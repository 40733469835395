import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routes from "../../configs/routes";

const SettingsNavigation = () => {
  const { t } = useTranslation();
  const currentPaths = window.location.pathname.split("/");

  return (
    <ul className="nav nav-segment">
      <li className="nav-item">
        <Link
          to={routes.auth.settings.account}
          className={`nav-link ${
            currentPaths.includes("account")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Account")}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={routes.auth.settings.security}
          className={`nav-link ${
            currentPaths.includes("security")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Security")}
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to={routes.auth.settings.workspace}
          className={`nav-link ${
            currentPaths.includes("workspace")
              ? "active cursor-default"
              : "cursor-pointer"
          }`}
        >
          {t("Workspace")}
        </Link>
      </li>
    </ul>
  );
};

export default SettingsNavigation;
