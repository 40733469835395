import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CollectionContext } from "../contexts/CollectionContext";
import { useFetchItem } from "../services/collectionService";

const CollectionProvider = ({ children }) => {
  const { uuid } = useParams();
  const { data, isPending, error } = useFetchItem(uuid);
  const [collection, setCollection] = useState({});

  useEffect(() => {
    const { content: collection = {} } = data || {};
    if (collection.length === 0 || isPending || error) return;
    setCollection(collection);
  }, [data, error, isPending]);

  return (
    <CollectionContext.Provider value={{ collection, isPending, error }}>
      {children}
    </CollectionContext.Provider>
  );
};

export default CollectionProvider;
