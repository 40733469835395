import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EndpointContext } from "../contexts/EndpointContext";
import { useFetchItem } from "../services/endpointService";

const EndpointProvider = ({ children }) => {
  const { uuid } = useParams();
  const { data, isPending, error } = useFetchItem(uuid);
  const [endpointData, setEndpointData] = useState({});

  useEffect(() => {
    const { content: endpoint = {} } = data || {};
    if (!data || isPending || error) return;
    setEndpointData({ endpoint, error, isPending });
  }, [data, error, isPending]);

  return (
    <EndpointContext.Provider value={endpointData}>
      {children}
    </EndpointContext.Provider>
  );
};

export default EndpointProvider;
