import { useTranslation } from "react-i18next";
import { useUpdatePassword } from "../../services/userService";
import Heading from "../../components/Heading/Heading";
import routes from "../../configs/routes";
import SettingsNavigation from "../../components/Navigation/SettingsNavigation";
import Alert from "../../components/Alert/Alert";

const SettingsBilling = () => {
  const { t } = useTranslation();
  const { error: updateError, isSuccess: isUpdateSuccess } =
    useUpdatePassword();

  return (
    <>
      <Heading
        to={routes.auth.home}
        back={t("Home")}
        title={t("Billing overview")}
        actionButtons={<SettingsNavigation />}
      />
      {updateError && (
        <Alert variant="danger" title="Opss!">
          <span>{t(updateError?.message)}</span>
        </Alert>
      )}
      {isUpdateSuccess && (
        <Alert variant="success" title="Updated">
          <span>{t("Your password has been updated successfully.")}</span>
        </Alert>
      )}
      <>
        <div className="row">
          <div className="col-md-6">
            <div className="card p-4">
              <div>
                <i className="bi bi-arrow-repeat icon fs-3 p-2 text-white ti ti-credit-card bg-secondary"></i>
              </div>
              <div className="my-4">
                <div className="d-flex align-items-center gap-2">
                  <h3>Free</h3>
                  <span className="badge bg-secondary">Recurring</span>
                </div>
                <p className="mt-1">Launch Your Journey</p>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <div className="fs-5 fw-bold">
                    <x-credit data-value="0">0</x-credit>
                  </div>
                  <p className="small text-muted">Credits per month</p>
                </div>
              </div>
              <hr />
              <div>
                <div className="fs-5 fw-bold">
                  <x-credit data-value="0">0</x-credit>
                </div>
                <div className="d-flex justify-content-between small">
                  <span className="text-muted">Credits left</span>
                  <span>0%</span>
                </div>
                <div className="progress mt-3">
                  <div className="progress-bar"></div>
                </div>
              </div>
              <div className="mt-4">
                <a href="app/billing/plans" className="btn btn-primary">
                  <div className="d-flex align-items-center gap-2">
                    <i className="ti ti-click fs-4"></i>
                    <span>Upgrade plan</span>
                  </div>
                </a>
              </div>
              <div className="mt-3">
                <p className="small text-muted">
                  Usage renews at&nbsp;
                  <x-time
                    datetime="1730355550"
                    title="Thu, 31 Oct 2024 06:19:10 GMT"
                  >
                    October 31 at 02:19 AM
                  </x-time>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card p-4">
              <div>
                <i className="ti ti-square-rounded-letter-t icon fs-3 p-2 text-white ti ti-credit-card bg-secondary"></i>
              </div>
              <div className="my-4">
                <div className="d-flex align-items-center gap-2">
                  <h2>Add-on credits</h2>
                  <span className="badge bg-secondary">
                    One-time, non-expiring
                  </span>
                </div>
                <p className="mt-1">
                  Permanent, non-renewing extras for your subscription, used
                  only after recurring credits run out.
                </p>
              </div>
              <div className="mt-auto">
                <div className="fs-5 fw-bold">
                  <x-credit data-value="0">0</x-credit>
                </div>
                <p className="small text-muted">Credits left</p>
              </div>
              <div className="my-2">
                <a href="app/billing/packs" className="btn btn-white">
                  <i className="ti ti-cube-plus"></i>
                  <span className="ms-2">Buy additional credits</span>
                </a>
              </div>
              <p className="mt-2 small text-muted">
                You can purchase additional credits to supplement your existing
                subscription.
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default SettingsBilling;
