import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "../../../Button/Button";
import { useDelete } from "../../../../services/collectionService";
import { useEffect } from "react";

const ModalDelete = ({ collectionUuid, show, handleClose }) => {
  const { t } = useTranslation();
  const { mutate: remove, error, data, isPending } = useDelete();

  const handleClearAll = async () => {
    remove(collectionUuid);
  };

  useEffect(() => {
    if (!isPending && data?.status) {
      handleClose();
    }
  }, [data, isPending, handleClose]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header
        closeButton
        className="d-flex justify-content-between align-items-center border-0"
      ></Modal.Header>
      <Modal.Body className="text-center col-lg-10 m-auto my-3">
        <i className="ti ti-trash fs-1 text-danger"></i>
        <h5 className="mt-2 mb-3">{t("Delete collection")}</h5>
        <p className="mb-2">
          {t("Are you sure you want to delete this collection?")}
        </p>
        <p>
          {t(
            "This action will delete all endpoints and requests associated with this collection."
          )}
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0">
        {error && (
          <div className="small text-danger" role="alert">
            {t("An error occurred while deleting this collection.")}
          </div>
        )}
        <div className="d-flex gap-2 justify-content-end">
          <Button variant="white" onClick={handleClose}>
            <span>{t("No, cancel")}</span>
          </Button>
          <Button
            variant="danger"
            isLoading={isPending}
            isDisabled={isPending}
            onClick={handleClearAll}
          >
            <span>{t("Yes, delete it")}</span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
