import { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { debounce } from "lodash";
import { useCheckDomain } from "../../../services/collectionService";
import { slugifyDomain, generateDomain } from "../../../utils/helpers";
import Input from "../../Forms/Input";
import Button from "../../Button/Button";
import Alert from "../../Alert/Alert";

// Domain states enum for better code organization
const DomainState = {
  INITIAL: "initial",
  CHECKING: "checking",
  AVAILABLE: "available",
  ERROR: "error",
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Your collection needs a name.").min(3).max(64),
  description: Yup.string().max(255),
  domain: Yup.string()
    .required("Your collection needs a domain.")
    .min(3)
    .max(16),
});

const BaseForm = ({
  initialData,
  onSubmit,
  isLoading,
  error,
  success,
  successMessage,
  submitButtonText,
  submitButtonIcon,
  uuid = null,
}) => {
  const { t } = useTranslation();
  const mutation = useCheckDomain();
  const [domainState, setDomainState] = useState(DomainState.INITIAL);

  const {
    trigger,
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const formValues = watch();

  const getDomainStateStyles = (state) => {
    switch (state) {
      case DomainState.AVAILABLE:
        return "text-success";
      case DomainState.ERROR:
        return "text-danger";
      case DomainState.CHECKING:
      case DomainState.INITIAL:
      default:
        return "text-secondary";
    }
  };

  const getDomainStateIndicator = (state) => {
    switch (state) {
      case DomainState.AVAILABLE:
        return <i className="ti ti-check fs-5 ms-2 text-success"></i>;
      case DomainState.ERROR:
        return <i className="ti ti-alert-circle fs-5 ms-2 text-danger"></i>;
      default:
        return null;
    }
  };

  const checkDomainAvailability = useCallback(
    async (value) => {
      // Handle initial/invalid states
      if (!value || value.length < 3 || errors.domain) {
        setDomainState(value ? DomainState.ERROR : DomainState.INITIAL);
        return;
      }
      setDomainState(DomainState.CHECKING);

      try {
        await mutation.mutateAsync({ domain: value, uuid });
        clearErrors("domain");
        setDomainState(DomainState.AVAILABLE);
      } catch {
        setError("domain", {
          type: "manual",
          message: t("This domain is already taken."),
        });
        setDomainState(DomainState.ERROR);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, uuid]
  );

  const debouncedCheck = useMemo(
    () => debounce((value) => checkDomainAvailability(value), 500),
    [checkDomainAvailability]
  );

  useEffect(() => {
    setDomainState(DomainState.CHECKING);
    setValue("name", initialData?.name ?? "");
    setValue("description", initialData?.description ?? "");
    handleDomainChange(initialData?.domain ?? generateDomain());

    return () => {
      debouncedCheck.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, debouncedCheck]);

  useEffect(() => {
    return () => {
      debouncedCheck.cancel();
    };
  }, [debouncedCheck]);

  const handleDomainChange = async (value) => {
    if (!value && value.length > 16) return;

    const domainSlug = slugifyDomain(value);
    setValue("domain", domainSlug);
    setDomainState(DomainState.INITIAL);

    debouncedCheck(domainSlug);

    await trigger("domain");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert variant="danger" title={t("Failed")}>
          {t("Something went wrong! Please try again")}
        </Alert>
      )}
      {success && (
        <Alert variant="success" title={t("Updated")}>
          {successMessage}
        </Alert>
      )}

      <div className="card p-4">
        <div className="d-flex align-items-center gap-1 mb-4">
          <i className="ti ti-library fs-4 text-primary" />
          <h3 className="fs-5 mb-0">{t("Collection details")}</h3>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Input
              label={t("Collection name")}
              name="name"
              autoComplete="off"
              maxLength="64"
              minLength="3"
              register={register}
              errors={errors}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-8">
            <Input
              label={t("Description")}
              name="description"
              register={register}
              errors={errors}
              autoComplete="off"
              maxLength="255"
            />
          </div>
        </div>
        <div className="grouping">
          <div className="row w-100">
            <div className="col-6 col-sm-6 col-md-4 col-lg-4 pe-0">
              <Input
                label={t("Subdomain")}
                name="domain"
                autoComplete="off"
                maxLength="16"
                minLength="3"
                onChange={(e) => handleDomainChange(e.target.value)}
                register={register}
                errors={errors}
                required
              />
            </div>
            <div className="col-6 col-sm-8 col-md-8 col-lg-8 p-0 mt-4 pt-2">
              {formValues.domain && (
                <>
                  <div className="ms-2 domain-view d-flex align-items-center">
                    <span className="protocol">https://</span>
                    <span
                      className={`fw-semibold ${getDomainStateStyles(
                        domainState
                      )}`}
                    >
                      {formValues.domain}
                    </span>
                    <span className="domain">.castapi.cloud</span>
                    {getDomainStateIndicator(domainState)}
                  </div>
                  {domainState === DomainState.ERROR && (
                    <div
                      role="button"
                      className="ms-2 text-primary small"
                      onClick={() => handleDomainChange(generateDomain())}
                    >
                      {t("[Try another]")}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 d-flex justify-content-end">
        <Button
          variant="primary"
          isLoading={isLoading}
          isDisabled={
            !isValid ||
            (errors && Object.keys(errors).length > 0) ||
            domainState === DomainState.CHECKING ||
            domainState === DomainState.ERROR
          }
        >
          <i className={`ti ${submitButtonIcon} fs-4`}></i>
          <span>{submitButtonText}</span>
        </Button>
      </div>
    </form>
  );
};

export default BaseForm;
