import { useQuery } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useFetchWidgets = (account) => {
  return useQuery({
    queryKey: ["widgets"],
    queryFn: () => fetchWithErrorHandling("/stats/widgets/count"),
    enabled: !!account,
  });
};
