import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

export const useFetchUser = () =>
  useQuery({
    queryKey: ["user"],
    queryFn: () => fetchWithErrorHandling("/user/load"),
  });

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ user }) =>
      fetchWithErrorHandling(`/user/update`, {
        method: "PUT",
        body: JSON.stringify(user),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });
};

export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: ({ password }) =>
      fetchWithErrorHandling(`/auth/password`, {
        method: "PUT",
        body: JSON.stringify(password),
      }),
  });
};

export const useLinkGithub = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/user/link/github`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};
