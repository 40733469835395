import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAccount } from "../../providers/AccountProvider";
import { useFetch } from "../../services/workspaceService";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import routes from "../../configs/routes";
import Tooltip from "../Tooltip/Tooltip";

const Workspace = () => {
  const { t } = useTranslation();
  const { session, setSession } = useAccount();
  const [switchModal, setSwitchModal] = useState(false);
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const { data: workspacesData } = useFetch();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (workspacesData?.content?.length > 0) {
      const { content } = workspacesData;
      setWorkspaces(content);

      if (!session.workspaceUuid) {
        setSession((prev) => ({
          ...prev,
          workspaceUuid: content[0].uuid,
        }));
        setSelectedWorkspace(content[0]);
      } else {
        const workspace = content.find((w) => w.uuid === session.workspaceUuid);
        setSelectedWorkspace(workspace);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.workspaceUuid, workspacesData]);

  const handleModalSwitch = () => {
    setSwitchModal(true);
  };

  const handleSelectWorkspace = (uuid) => {
    const workspace = workspaces.find((w) => w.uuid === uuid);

    // Prevent closing the modal if the selected workspace is already selected
    if (selectedWorkspace?.uuid === workspace.uuid) {
      return;
    }

    setSession((prev) => ({
      ...prev,
      workspaceUuid: workspace.uuid,
    }));

    setSelectedWorkspace(workspace);

    queryClient.invalidateQueries({
      queryKey: ["collections", "widgets"],
    });

    setSwitchModal(false);

    // force a page reload
    window.location.reload();
  };

  return (
    <div className="w-100">
      <Dropdown>
        <Dropdown.Toggle
          variant="none"
          className="p-0 border-0 w-100"
          id="dropdown-worspace"
          drop="down"
        >
          <div className="text-start card p-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-block text-truncate">
                <small className="text-muted">{t("Workspace")}</small>
                <div className="h6 mb-0 text-truncate">
                  {selectedWorkspace?.name ? (
                    selectedWorkspace?.name
                  ) : (
                    <div
                      className="placeholder-wave"
                      style={{ width: "120px", height: "16.8px" }}
                    >
                      <div className="placeholder rounded w-100 h-100"></div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <i className="ti ti-chevron-down text-muted fs-4"></i>
              </div>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          <div className="dropdown-item-text">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-block text-truncate">
                <div className="h5 mb-0 text-truncate">
                  {selectedWorkspace?.name}
                </div>
                <div className="small">Free</div>
              </div>

              <Tooltip message={t("Switch workplace")} placement="top">
                <button
                  className="btn btn-icon p-0"
                  onClick={handleModalSwitch}
                >
                  <i className="ti ti-switch-horizontal fs-4"></i>
                </button>
              </Tooltip>
            </div>
          </div>
          <Dropdown.Divider />
          <Link
            to={routes.auth.settings.workspace}
            className="dropdown-item d-flex align-items-center gap-2"
          >
            <div className="d-flex align-items-center">
              <i className="ti ti-settings-2 fs-4"></i>
            </div>
            <div>
              <div className="d-block">{t("Overview")}</div>
            </div>
          </Link>
        </Dropdown.Menu>
      </Dropdown>
      <Modal
        show={switchModal}
        onHide={() => setSwitchModal(false)}
        animation={true}
        centered
      >
        <div className="p-3">
          <Modal.Header
            closeButton
            className="d-flex justify-content-between align-items-center border-0"
          >
            <h5 className="modal-title">{t("Your workspaces")}</h5>
          </Modal.Header>
          <Modal.Body className="pt-3">
            {workspaces.length === 0 ? (
              <p>No workspaces found</p>
            ) : (
              workspaces?.map((workspace) => (
                <div
                  className={`px-3 py-2 mb-2 ${
                    selectedWorkspace?.uuid === workspace.uuid
                      ? "cursor-default"
                      : "cursor-pointer card hover-flex"
                  } `}
                  role="button"
                  key={workspace.uuid}
                  onClick={() => handleSelectWorkspace(workspace.uuid)}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-block text-start">
                      <div className="h5 mb-0">{workspace.name}</div>
                      <div className="small">Free</div>
                    </div>
                    {selectedWorkspace?.uuid === workspace.uuid ? (
                      <div
                        className="d-flex gap-1 align-items-center bg-success rounded  py-1 px-2"
                        style={{ "--bs-bg-opacity": 0.1 }}
                      >
                        <span className="text-success">Selected</span>
                      </div>
                    ) : (
                      <div className="child gap-1 align-items-center bg-light rounded py-1 px-2">
                        <i className="ti ti-switch-horizontal fs-4"></i>
                        <span>Switch</span>
                      </div>
                    )}
                  </div>
                </div>
              ))
            )}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Workspace;
