import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SearchBox from "../../SearchBox/SearchBox";
import Item from "../Item/Item";
import ButtonLink from "../../Button/ButtonLink";
import routes from "../../../configs/routes";

const List = ({ originalEndpoints, endpoints, onSearch }) => {
  const { t } = useTranslation();
  const { uuid } = useParams();

  return (
    <div className="endpoint__list">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <SearchBox
          itemFilter="name"
          data={originalEndpoints}
          onSearch={onSearch}
        />
        <div className="action__buttons">
          <ButtonLink
            href={routes.auth.endpoint.create(uuid)}
            variant="secondary"
          >
            <i className="ti ti-square-rounded-plus fs-4"></i>
            <span>{t("New endpoint")}</span>
          </ButtonLink>
        </div>
      </div>
      {endpoints.length > 0 ? (
        <ul className="list-unstyled">
          {endpoints?.map((endpoint) => (
            <Item key={endpoint.uuid} endpoint={endpoint} />
          ))}
        </ul>
      ) : (
        <div className="text-center">
          <h4 className="bold">{t("No endpoint found.")}</h4>
          <p>{t("We couldn't find any endpoint.")}</p>
        </div>
      )}
    </div>
  );
};

export default List;
