import { useUpdate } from "../../../services/collectionService";
import { useTranslation } from "react-i18next";
import BaseForm from "./BaseForm";

const EditForm = ({ collectionData }) => {
  const { t } = useTranslation();

  const {
    mutate: update,
    error: updateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdatePending,
  } = useUpdate();

  const handleUpdate = (formData) => {
    update({
      uuid: collectionData.uuid,
      collection: {
        name: formData.name,
        description: formData.description,
        domain: formData.domain,
      },
    });
  };

  return (
    <BaseForm
      initialData={collectionData}
      onSubmit={handleUpdate}
      error={updateError}
      success={isUpdateSuccess}
      isLoading={isUpdatePending}
      successMessage={t("Collection updated successfully.")}
      submitButtonText={t("Save changes")}
      submitButtonIcon="ti-device-floppy"
      uuid={collectionData?.uuid}
    />
  );
};

export default EditForm;
