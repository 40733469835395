import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../providers/AuthProvider";
import { useAccount } from "../../providers/AccountProvider";
import routes from "../../configs/routes";
import { useLogout } from "../../services/authService";

const AccountDropdown = () => {
  const { t } = useTranslation();
  const { logout, session } = useAuth();
  const { account } = useAccount();
  const { mutate: serverLogout } = useLogout();

  const handleLogout = () => {
    serverLogout({
      token: session.token,
    });

    logout();
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="none"
        className="p-0 border-0"
        id="dropdown-account"
        drop="up"
      >
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="icon avatar bg-secondary d-flex align-items-center justify-content-center">
              {account?.avatar ? (
                <img
                  className="img-fluid"
                  src={account.avatar}
                  alt={account.fullname}
                  width={40}
                  height={40}
                />
              ) : (
                <div className="text-light">{account.initials}</div>
              )}
            </div>
          </div>
          <div className="ms-2 text-start">
            <div className="fw-normal mb-0">{account?.firstName}</div>
          </div>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="dropdown-item-text d-flex align-items-center">
          <div className="d-flex align-items-center">
            <div className="icon avatar bg-secondary d-flex align-items-center justify-content-center">
              <div className="text-light">{account.initials}</div>
            </div>
          </div>
          <div className="ms-2 text-start">
            <div className="fw-semibold mb-0">{account?.fullname}</div>
            <div className="small">{account?.email}</div>
          </div>
        </div>
        <Dropdown.Divider />
        <Link
          to={routes.auth.settings.account}
          className="dropdown-item d-flex align-items-center gap-2"
        >
          <div className="d-flex align-items-center">
            <i className="ti ti-settings fs-4"></i>
          </div>
          <div>
            <div className="d-block mb-0 fw-semibold">{t("Settings")}</div>
            <small className="d-block">{t("Account settings")}</small>
          </div>
        </Link>
        <Dropdown.Divider />
        <div
          className="dropdown-item d-flex align-items-center gap-2"
          role="button"
          tabIndex="0"
          onClick={handleLogout}
        >
          <div className="d-flex align-items-center">
            <i className="ti ti-logout text-danger fs-4 fw-normal" />
          </div>
          <div>
            <div className="d-block mb-0 fw-semibold">{t("Logout")}</div>
            <small className="d-block">{t("Exit from the app")}</small>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AccountDropdown;
