import { useState } from "react";
import "./Forms.scss";

export default function InputPassword({
  label,
  name,
  register,
  errors,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="mb-3 password-group position-relative">
      <label htmlFor={name} className="form-label">
        <span>{label}</span>
        {rest.required && <span className="text-danger">*</span>}
      </label>
      <input
        type={showPassword ? "text" : "password"}
        id={name}
        {...register(name)}
        className={`form-control ${errors[name] ? "is-invalid" : "field"}`}
        {...rest}
      />
      {errors[name] && (
        <div className="invalid-feedback">{errors[name].message}</div>
      )}
      <div className="position-absolute end-0 end-0 password-toggle me-2">
        <button
          type="button"
          className="btn btn-sm btn-icon btn-ghost-secondary"
          onClick={toggleShowPassword}
        >
          <i
            className={`text-muted ti ${
              showPassword ? "ti-eye" : "ti-eye-off"
            } fs-4`}
          ></i>
        </button>
      </div>
    </div>
  );
}
