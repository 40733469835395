const StatusBadge = ({ status }) => {
  const responseCodeToColor = (code) => {
    if (code >= 100 && code < 200) {
      return "pink";
    } else if (code >= 200 && code < 300) {
      return "success";
    } else if (code >= 300 && code < 400) {
      return "info";
    } else if (code >= 400 && code < 500) {
      return "warning";
    } else if (code >= 500) {
      return "danger";
    }
  };

  return (
    <span
      className={`d-flex align-items-center gap-1 text-${responseCodeToColor(
        status
      )}`}
    >
      <i className="ti ti-circle-dot fs-5"></i>
      <span>{status}</span>
    </span>
  );
};

export default StatusBadge;
