import { useState, createContext, useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utils/token";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useFetchUser } from "../services/userService";
import routes from "../configs/routes";

const AccountContext = createContext();

const initials = (user) => {
  return `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`;
};

const fullname = (user) => {
  return `${user.firstName} ${user.lastName}`;
};

export const AccountProvider = ({ children }) => {
  const navigate = useNavigate();
  const [session, setSession] = useLocalStorage("sessionCastAPI", null);
  const [user, setUser] = useState({});
  const { data: userData, error } = useFetchUser();

  useEffect(() => {
    if (!getToken()) {
      setSession(null);
      navigate(routes.public.login, { replace: true });
      return;
    }

    if (error) {
      setSession(null);
      navigate(routes.public.login, { replace: true });
      return;
    }

    // Check if session has userUuid, if not set it
    if (!session?.userUuid && userData?.content) {
      setSession((prev) => ({
        ...(prev ?? {}),
        userUuid: userData?.content.uuid,
      }));
    }

    // user data is available, set it to the state
    if (userData?.content) {
      setUser(userData.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, error]);

  const value = useMemo(
    () => ({
      session,
      setSession,
      account: {
        ...user,
        initials: initials(user),
        fullname: fullname(user),
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, session]
  );

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};

export const useAccount = () => {
  return useContext(AccountContext);
};
