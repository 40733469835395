import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreate } from "../../../services/collectionService";
import BaseForm from "./BaseForm";
import routes from "../../../configs/routes";

const CreateForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    mutate: create,
    error: createError,
    isSuccess: createSuccess,
    isPending: createPending,
    data: collectionData,
  } = useCreate();

  useEffect(() => {
    if (createSuccess && collectionData) {
      const { uuid } = collectionData.content;
      navigate(`${routes.auth.endpoints(uuid)}?onboard=collection`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionData, createSuccess]);

  const handleCreate = (formData) => {
    create({
      name: formData.name,
      description: formData.description,
      domain: formData.domain,
    });
  };

  return (
    <BaseForm
      onSubmit={handleCreate}
      error={createError}
      success={createSuccess}
      isLoading={createPending}
      successMessage={t("Collection created successfully.")}
      submitButtonText={t("Create collection")}
      submitButtonIcon="ti-square-rounded-plus"
    />
  );
};

export default CreateForm;
