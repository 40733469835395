import { useState, useEffect } from "react";
import "./Forms.scss";

export default function InputNumber({
  id,
  label,
  defaultValue,
  onChange,
  details,
  ...props
}) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (defaultValue === undefined || defaultValue === null || !defaultValue) {
      setValue("");
    } else {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        type="number"
        id={id}
        value={value}
        onChange={handleChange}
        className="form-control"
        {...props}
      />
      {details && <div className="form-text small">{details}</div>}
    </div>
  );
}
