const methods = [
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-info"></i>
        <span>GET</span>
      </div>
    ),
    value: "GET",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-success"></i>
        <span>POST</span>
      </div>
    ),
    value: "POST",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-warning"></i>
        <span>PUT</span>
      </div>
    ),
    value: "PUT",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-warning"></i>
        <span>PATCH</span>
      </div>
    ),
    value: "PATCH",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-danger"></i>
        <span>DELETE</span>
      </div>
    ),
    value: "DELETE",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-info"></i>
        <span>OPTIONS</span>
      </div>
    ),
    value: "OPTIONS",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-info"></i>
        <span>TRACE</span>
      </div>
    ),
    value: "TRACE",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-1">
        <i className="ti ti-point-filled fs-4 text-info"></i>
        <span>ANY</span>
      </div>
    ),
    value: "ANY",
  },
];

const bodyTypes = [
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <i className="ti ti-file-code fs-5"></i>
        <span>JSON</span>
      </div>
    ),
    value: "json",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <i className="ti ti-file-type-xml fs-5"></i>
        <span>XML</span>
      </div>
    ),
    value: "xml",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <i className="ti ti-file-type-html fs-5"></i>
        <span>HTML</span>
      </div>
    ),
    value: "html",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <i className="ti ti-file-type-txt fs-5"></i>
        <span>Plain Text</span>
      </div>
    ),
    value: "plain-text",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <i className="ti ti-file-type-js fs-5"></i>
        <span>JavaScript</span>
      </div>
    ),
    value: "javascript",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <i className="ti ti-file-type-css fs-5"></i>
        <span>Form Data</span>
      </div>
    ),
    value: "form-data",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <i className="ti ti-file-type-css fs-5"></i>
        <span>Form URL Encoded</span>
      </div>
    ),
    value: "form-url-encoded",
  },
];

export { methods, bodyTypes };
