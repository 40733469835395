import { getAutorization, getWorkspace } from "../utils/token";

const BASE_URL = process.env.REACT_APP_API_URL;

const handleResponse = async (response, errorMessage) => {
  if (!response.ok) {
    const text = await response.text();

    throw new Error(text || errorMessage);
  }
  return response.json();
};

const fetchWithErrorHandling = async (url, options = {}) => {
  options.headers = {
    ...options.headers,
    "Content-Type": "application/json",
    Authorization: getAutorization(),
    Workspace: getWorkspace(),
  };
  const response = await fetch(BASE_URL + url, options);

  return handleResponse(response, "Something went wrong. Please try again.");
};

export { handleResponse, fetchWithErrorHandling };
