import { useTranslation } from "react-i18next";
import Test from "./Test";
import StatusBadge from "./StatusBadge";

import Options from "./Options";
import "./Item.scss";
import CopyToClipboard from "../../CopyToClipboard/CopyToClipboard";
import { apiDomain } from "../../../utils/helpers";

const Item = ({ endpoint }) => {
  const { t } = useTranslation();

  return (
    <li className={`endpoint__item card mb-3`}>
      <div className="card-body p-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="details">
            <strong className="fw-semibold mb-2 d-block">
              {endpoint.name}
            </strong>
            <div className="d-flex w-100">
              <div className="bg-light rounded-1 p-1 d-flex align-items-center gap-1">
                <span
                  className={`request rounded-1 px-2 py-0 method__${endpoint.method}`}
                >
                  {endpoint.method}
                </span>

                <CopyToClipboard
                  text={t("Copy full URL")}
                  label={
                    <span className="font-monospace">{endpoint.path}</span>
                  }
                  value={apiDomain(endpoint?.domain, endpoint.path)}
                />
              </div>
            </div>
          </div>
          <div className="actions d-flex justify-content-between align-items-center gap-2">
            <div className="me-5">
              <StatusBadge status={endpoint.code} />
            </div>
            <Test endpoint={endpoint} />
            <div className="actions">
              <Options uuid={endpoint.uuid} onDelete={() => {}} />
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Item;
