import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const StatusCodeSuggester = ({
  title = "",
  description = "",
  onSelectStatusCode,
}) => {
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);

  // Define keyword mappings for status codes - simplified version
  const statusCodeMappings = {
    // 2xx Success
    200: [
      "get",
      "fetch",
      "list",
      "retrieve",
      "search",
      "find",
      "query",
      "success",
      "ok",
      "read",
      "view",
      "download",
      "calculate",
      "validate",
      "check",
      "verify",
    ],
    201: [
      "create",
      "new",
      "add",
      "register",
      "sign up",
      "signup",
      "post",
      "generate",
      "make",
      "submit",
      "upload",
      "insert",
      "store",
    ],
    202: [
      "accepted",
      "process",
      "queue",
      "schedule",
      "background",
      "async",
      "later",
      "deferred",
      "pending",
    ],
    204: [
      "delete",
      "remove",
      "clear",
      "clean",
      "unsubscribe",
      "dismiss",
      "logout",
      "sign out",
      "signout",
      "deactivate",
      "disable",
    ],

    // 3xx Redirection
    301: ["moved", "permanent", "redirect", "change", "forward", "migrate"],
    302: ["temporary", "redirect", "forward", "temporary redirect"],
    304: ["not modified", "cache", "unchanged", "same", "no change"],

    // 4xx Client Errors
    400: [
      "invalid",
      "bad",
      "malformed",
      "validation",
      "wrong",
      "incorrect",
      "failed validation",
      "invalid input",
      "bad format",
      "syntax error",
      "parse error",
      "invalid parameter",
    ],
    401: [
      "unauthorized",
      "not authorized",
      "login required",
      "authentication",
      "login",
      "signin",
      "sign in",
      "auth",
      "unauthenticated",
      "no token",
      "invalid token",
      "expired token",
      "no credentials",
    ],
    403: [
      "forbidden",
      "not allowed",
      "permission",
      "denied",
      "no access",
      "unauthorized access",
      "restricted",
      "no permission",
      "insufficient",
      "not permitted",
      "blocked",
    ],
    404: [
      "not found",
      "missing",
      "doesn't exist",
      "gone",
      "unavailable",
      "no such",
      "unknown",
      "deleted",
      "removed",
      "no longer",
      "invalid id",
    ],
    405: [
      "method not allowed",
      "invalid method",
      "wrong method",
      "not supported",
      "unsupported method",
    ],
    408: ["timeout", "timed out", "too slow", "no response", "long"],
    409: [
      "conflict",
      "duplicate",
      "already exists",
      "collision",
      "concurrent",
      "already taken",
      "in use",
      "exists",
      "unique constraint",
    ],
    413: [
      "too large",
      "payload too large",
      "file too big",
      "oversized",
      "size limit",
      "maximum size",
    ],
    422: [
      "unprocessable",
      "semantic error",
      "invalid data",
      "business rule",
      "logic error",
      "validation failed",
      "business validation",
    ],
    429: [
      "too many requests",
      "rate limit",
      "throttle",
      "slow down",
      "try again later",
      "quota exceeded",
    ],

    // 5xx Server Errors
    500: [
      "error",
      "fail",
      "crash",
      "exception",
      "internal",
      "unexpected",
      "server error",
      "runtime error",
      "system error",
      "fatal",
      "unhandled",
      "bug",
    ],
    501: [
      "not implemented",
      "unimplemented",
      "todo",
      "future",
      "coming soon",
      "planned",
      "unavailable feature",
    ],
    502: [
      "bad gateway",
      "gateway",
      "proxy error",
      "upstream",
      "connection failed",
    ],
    503: [
      "unavailable",
      "maintenance",
      "overloaded",
      "temporary unavailable",
      "service down",
      "capacity",
      "overwhelmed",
      "retry later",
      "down",
    ],
    504: [
      "gateway timeout",
      "upstream timeout",
      "timeout",
      "too slow",
      "connection timeout",
    ],
  };

  useEffect(() => {
    const findSuggestions = () => {
      const combinedText = `${title} ${description}`.toLowerCase();
      const suggestions = new Map(); // Using Map to store scores

      // Complex matching logic
      Object.entries(statusCodeMappings).forEach(([code, keywords]) => {
        let score = 0;

        // Check for exact matches (highest priority)
        keywords.forEach((keyword) => {
          if (combinedText.includes(keyword.toLowerCase())) {
            score += 10;
          }

          // Check for partial matches (medium priority)
          if (
            keyword.length > 4 &&
            combinedText.includes(keyword.slice(0, -1).toLowerCase())
          ) {
            score += 5;
          }
        });

        // Context-based scoring
        if (
          parseInt(code) >= 500 &&
          /error|fail|crash|exception/i.test(combinedText)
        ) {
          score += 8;
        }

        if (
          parseInt(code) === 401 &&
          /login|auth|token|credential/i.test(combinedText)
        ) {
          score += 8;
        }

        if (
          parseInt(code) === 403 &&
          /admin|superuser|privilege/i.test(combinedText)
        ) {
          score += 8;
        }

        // HTTP method-based scoring
        const methodPatterns = {
          GET: [200, 304, 404],
          POST: [201, 400, 409],
          PUT: [200, 400, 404],
          PATCH: [200, 400, 404],
          DELETE: [204, 404],
        };

        Object.entries(methodPatterns).forEach(([method, codes]) => {
          if (
            combinedText.includes(method.toLowerCase()) &&
            codes.includes(parseInt(code))
          ) {
            score += 5;
          }
        });

        // Add to suggestions if score is significant
        if (score > 0) {
          suggestions.set(parseInt(code), score);
        }
      });

      // If no suggestions, add contextual defaults
      if (suggestions.size === 0) {
        if (/get|list|fetch|view|show/i.test(combinedText)) {
          suggestions.set(200, 5);
        } else if (/create|add|new/i.test(combinedText)) {
          suggestions.set(201, 5);
        }
      }

      // Sort by score and convert to array
      return Array.from(suggestions.entries())
        .sort((a, b) => b[1] - a[1]) // Sort by score
        .map(([code]) => code) // Extract just the codes
        .slice(0, 3); // Limit to top 3 suggestions
    };

    setSuggestions(findSuggestions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, title]);

  if (!title && !description) {
    return null;
  }

  // Helper function to get status code description
  const getStatusCodeDescription = (code) => {
    switch (code) {
      // 2xx Success
      case 200:
        return "OK";
      case 201:
        return "Created";
      case 202:
        return "Accepted";
      case 204:
        return "No Content";
      // 3xx Redirection
      case 301:
        return "Moved Permanently";
      case 302:
        return "Found";
      case 304:
        return "Not Modified";
      // 4xx Client Errors
      case 400:
        return "Bad Request";
      case 401:
        return "Unauthorized";
      case 403:
        return "Forbidden";
      case 404:
        return "Not Found";
      case 405:
        return "Method Not Allowed";
      case 408:
        return "Request Timeout";
      case 409:
        return "Conflict";
      case 413:
        return "Payload Too Large";
      case 422:
        return "Unprocessable Entity";
      case 429:
        return "Too Many Requests";
      // 5xx Server Errors
      case 500:
        return "Internal Server Error";
      case 501:
        return "Not Implemented";
      case 502:
        return "Bad Gateway";
      case 503:
        return "Service Unavailable";
      case 504:
        return "Gateway Timeout";
      default:
        return "";
    }
  };

  if (!title && !description) {
    return null;
  }

  if (suggestions.length === 0) {
    return null;
  }

  return (
    <div className="d-flex">
      <div className="d-flex align-items-center gap-1 text-gradient">
        <i className="ti ti-wand"></i>
        <span className="mb-0">{t("Suggestion")}:</span>
      </div>
      <div className="d-flex ms-2">
        {suggestions.map((code, index) => (
          <div
            key={code}
            className="d-flex link-hover-primary"
            role="button"
            onClick={() => onSelectStatusCode(code)}
          >
            <span className="">{code}</span>
            <span className="ms-1">{getStatusCodeDescription(code)}</span>
            {index < suggestions.length - 1 && <span className="me-1">,</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusCodeSuggester;
