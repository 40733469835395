import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCreateAccount } from "../../services/authService";
import { useAuth } from "../../providers/AuthProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../../components/Forms/Input";
import Button from "../../components/Button/Button";
import routes from "../../configs/routes";
import logo from "../../components/Header/logos/logo.svg";
import Alert from "../../components/Alert/Alert";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Provide your first name"),
  lastName: Yup.string().required("Provide your last name"),
  email: Yup.string()
    .email("It looks like your email is invalid")
    .required("An email address is required here"),
  password: Yup.string()
    .min(6, "Your password must be at least 6 characters")
    .required("You need a password to secure your account"),
  terms: Yup.bool().oneOf(
    [true],
    "Please read and accept the Terms & Conditions"
  ),
});

const Signup = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { mutate: createAccount, isPending, data, error } = useCreateAccount();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (!data) return;
    const { token } = data.content;
    login(token);
  }, [data, login]);

  const handleSignupAction = (formData) => {
    createAccount({
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      password: formData.password,
    });
  };

  return (
    <div className="container-fluid px-3">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0">
          <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
            <div className="d-none d-lg-flex justify-content-between">
              <Link to={routes.auth.home}>
                <img src={logo} alt="castAPI" width={180} height={45} />
              </Link>
            </div>
          </div>
          {error && (
            <Alert variant="danger" title={t("Oops")} position="top-end">
              {error.message}
            </Alert>
          )}
          <div style={{ maxWidth: "28rem" }}>
            <h1 className="mb-5 fw-bold">
              <span className="me-1">Mock APIs effortlessly</span>
              <span className="ms-1">🚀</span>
            </h1>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-1 lead">All-in-one tool:</h3>
                  <span>Create, test, and manage mock APIs with ease.</span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-1 lead">Custom Endpoints:</h3>
                  <span>Build flexible, versioned APIs for any use case.</span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-1 lead">
                    Collaborate Seamlessly:
                  </h3>
                  <span>Share your API collections across teams.</span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-1 lead">Secure & Scalable:</h3>
                  <span>
                    Manage mocks with built-in security and reliability.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
          <div
            className="w-100 content-space-t-4 content-space-t-lg-2 content-space-b-1"
            style={{ maxWidth: "28rem" }}
          >
            <div className="d-flex d-lg-none justify-content-center mb-5">
              <Link to={routes.auth.home}>
                <img src={logo} alt="castAPI" width={180} height={45} />
              </Link>
            </div>

            <h2 className="h2 fw-bolder text-center text-md-start mb-4">
              {t("Let's get started...")}
            </h2>

            {/*
              <div className="d-grid mb-4">
                <a className="btn btn-white" href="/">
                  <span className="d-flex justify-content-center align-items-center">
                    <i className="bi bi-google me-2"></i>
                    Sign up with Google
                  </span>
                </a>
              </div>

              <small className="divider-center d-flex align-items-center text-muted mb-4">
                or
              </small>
              */}

            <form onSubmit={handleSubmit(handleSignupAction)}>
              <div className="row">
                <div className="col">
                  <Input
                    label={t("First name")}
                    name="firstName"
                    type="text"
                    register={register}
                    errors={errors}
                    autoComplete="given-name"
                    maxLength={16}
                  />
                </div>
                <div className="col">
                  <Input
                    label={t("Last name")}
                    name="lastName"
                    type="text"
                    register={register}
                    errors={errors}
                    autoComplete="family-name"
                    maxLength={32}
                  />
                </div>
              </div>

              <Input
                label={t("Email address")}
                name="email"
                type="email"
                register={register}
                errors={errors}
                autoComplete="email"
                maxLength={255}
              />

              <Input
                label={t("Password")}
                name="password"
                type="password"
                register={register}
                errors={errors}
                autoComplete="new-password"
                minLength={6}
              />

              <div className="form-check mb-4">
                <input
                  type="checkbox"
                  {...register("terms")}
                  id="terms"
                  className={`form-check-input`}
                />
                <label className="form-check-label" htmlFor="terms">
                  <span>{t("I accept the")}</span>
                  <Link to={routes.public.login} className="ms-1">
                    {t("Terms and Conditions")}
                  </Link>
                  <span>.</span>
                </label>
                {errors.terms && (
                  <div className="text-danger small mt-1">
                    {errors.terms.message}
                  </div>
                )}
              </div>

              <div className="mt-4 d-grid gap-2">
                <Button
                  type="submit"
                  isLoading={isPending}
                  isDisabled={isPending || !isValid}
                  variant={"primary"}
                  size={"lg"}
                >
                  <span>{t("Create my account")}</span>
                </Button>
              </div>
            </form>
            <p className="text-muted mt-5 text-center text-md-start">
              <span>{t("Already have an account?")}</span>
              <Link className="ms-1 text-muted" to={routes.public.login}>
                {t("Log in")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
