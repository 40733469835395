import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "./SearchBox.scss";

const SearchBox = ({ data, onSearch, itemFilter }) => {
  const [focus, setFocus] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    const filter = itemFilter || "title";

    const filteredData = data.filter((item) =>
      item[filter].toLowerCase().includes(value.toLowerCase())
    );
    onSearch(filteredData);
  };

  const handleInputFocus = () => {
    setFocus(true);
  };

  const handleInputBlur = () => {
    setFocus(false);
  };

  const handleInputClick = (e) => {
    e.preventDefault();
    document.querySelector(".search-box input").focus();
    setFocus(true);
  };

  const handleKeyDown = (e) => {
    if (e.metaKey && e.key === "k") {
      e.preventDefault();
      document.querySelector(".search-box input").focus();
    }
  };

  return (
    <div
      onClick={handleInputClick}
      className={`search-box position-relative ${
        focus || query ? "on-focus" : "kbd-ready"
      }`}
    >
      <div className="search-box__icon position-absolute start-0">
        <i className="ti ti-search fs-4"></i>
      </div>
      <Form.Control
        type="search"
        id="search"
        value={query}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        autoComplete="off"
      />

      <div
        className={`search-box__shortcut position-absolute end-0 fade ${
          focus ? "kbd" : "show"
        }`}
      >
        <kbd className="text-muted">⌘ + k</kbd>
      </div>
    </div>
  );
};

export default SearchBox;
