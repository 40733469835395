import i18next from "i18next";
import Select from "../Forms/Select";
import flagUS from "./flags/us.svg";
import flagBR from "./flags/br.svg";

const options = [
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <img
          id=""
          src={flagUS}
          alt="English"
          className="icon img-fluid"
          width={20}
          height={20}
        />
        <span>English</span>
      </div>
    ),
    value: "en",
  },
  {
    label: (
      <div className="d-flex align-items-center gap-2">
        <img
          src={flagBR}
          alt="Português"
          className="icon img-fluid"
          width={20}
          height={20}
        />
        <span>Português (BR)</span>
      </div>
    ),
    value: "pt_BR",
  },
];

const LanguageSelectbox = () => {
  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  return (
    <Select
      name="language"
      label="Language"
      defaultValue={i18next.language}
      onChange={changeLanguage}
      options={options}
    />
  );
};

export default LanguageSelectbox;
