const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const slugifyDomain = (text) => {
  return text
    .toString() // Convert to string
    .toLowerCase() // Convert to lowercase
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/[^\w-]+/g, "") // Remove all non-word characters except dashes
    .replace(/--+/g, "-") // Replace multiple dashes with a single dash
    .replace(/^-/, ""); // If starts with a dash, remove it
};

const slugifyPath = (input) => {
  let slug = input
    .toString()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/\/\s+/g, "/") // Ensure no spaces after slashes
    .replace(/^-/, ""); // Remove leading and trailing dashes

  // Ensure the path starts with a slash
  if (!slug.startsWith("/")) {
    slug = "/" + slug;
  }
  return slug;
};

const generateDomain = (length = 8) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from({ length }, () =>
    chars.charAt(Math.floor(Math.random() * chars.length))
  ).join("");
};

const apiDomain = (domain, path = "") => {
  // If development environment
  if (process.env.REACT_APP_ENV === "development") {
    return `http://${domain}.${process.env.REACT_APP_MOCK_URL}${path}`;
  }
  return `https://${domain}.castapi.cloud${path}`;
};

const formateDate = (date) => {
  const d = new Date(date);
  return d.toLocaleString();
};

const parseError = (error) => {
  if (!error)
    return {
      message: "Something went wrong, Try again",
    };

  if (typeof error === "object" && error?.message) {
    const errorString = error?.toString();
    const jsonString = errorString.substring(errorString.indexOf("{"));
    return JSON.parse(jsonString);
  }

  return { message: error.toString() };
};

export {
  isObjectEmpty,
  apiDomain,
  slugifyDomain,
  slugifyPath,
  formateDate,
  generateDomain,
  parseError,
};
