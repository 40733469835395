import { useTranslation } from "react-i18next";
import CreateForm from "../Form/CreateForm";
import Heading from "../../Heading/Heading";
import routes from "../../../configs/routes";

const Create = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        back={t("Home")}
        to={routes.auth.home}
        title={t("Create collection")}
      />
      <div className="sub-container">
        <CreateForm />
      </div>
    </>
  );
};

export default Create;
