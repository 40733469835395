import { useMutation } from "@tanstack/react-query";
import { fetchWithErrorHandling } from "../utils/service";

const BASE_URL = process.env.REACT_APP_API_URL;

// Mutation for creating an account
export const useCreateAccount = () => {
  return useMutation({
    mutationKey: ["signup"],
    mutationFn: async (data) => {
      const response = await fetch(`${BASE_URL}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`Failed to create account: ${response.statusText}`);
      }
      return response.json();
    },
  });
};

export const useLogin = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/auth/login`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};

export const useGithub = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/auth/github/callback`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: (data) =>
      fetchWithErrorHandling(`/auth/logout`, {
        method: "POST",
        body: JSON.stringify(data),
      }),
  });
};

// Mutation for changing password
export const useChangePassword = () => {
  return useMutation({
    mutationKey: ["changePassword"],
    mutationFn: async (data) => {
      const response = await fetch(`${BASE_URL}/auth/password`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
        body: JSON.stringify({ password: data.password }),
      });
      if (!response.ok) {
        throw new Error(`Failed to change password: ${response.statusText}`);
      }
      return response.json();
    },
  });
};
