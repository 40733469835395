import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLogin } from "../../services/authService";
import { useAuth } from "../../providers/AuthProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../../components/Forms/Input";
import Button from "../../components/Button/Button";
import routes from "../../configs/routes";
import logo from "../../components/Header/logos/logo.svg";
import Alert from "../../components/Alert/Alert";
import GitHubLogin from "../../components/Auth/GitHubLogin";
import { parseError } from "../../utils/helpers";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("It looks like your email is invalid")
    .required("Provide your email address"),
  password: Yup.string()
    .min(6, "Your password must be at least 6 characters")
    .required("Your password is required here"),
  remember: Yup.bool(),
});

const Login = () => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { mutate: userLogin, isPending, error, data } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  useEffect(() => {
    if (!data) return;
    const { token } = data.content;
    login(token);
  }, [data, login]);

  const handleLoginAction = (formData) => {
    userLogin(formData);
  };

  return (
    <div className="container-fluid px-3">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-flex justify-content-center align-items-center min-vh-lg-100 position-relative bg-light px-0">
          <div className="position-absolute top-0 start-0 end-0 mt-3 mx-3">
            <div className="d-none d-lg-flex justify-content-between">
              <Link to={routes.auth.home} aria-label="Home">
                <img src={logo} alt="castAPI" width={180} height={45} />
              </Link>
            </div>
          </div>
          {error && (
            <Alert variant="danger" title={t("Oops")} position="top-end">
              {parseError(error)?.message}
            </Alert>
          )}
          <div style={{ maxWidth: "28rem" }}>
            <h1 className="mb-5 fw-bold">
              <span className="d-block">
                Access your <span className="text-primary">castAPI</span>
              </span>
              <span className="mt-1">workspace to mock APIs 🚀</span>
            </h1>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-1 lead">Manage everything:</h3>
                  <span>Log in to view and manage your mock APIs.</span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div className="">
                  <h3 className="fw-semibold mb-1 lead">Custom Endpoints:</h3>
                  <span>
                    Continue building and testing flexible, versioned APIs.
                  </span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-1 lead">Team Collaboration:</h3>
                  <span>Work with your team on shared API collections.</span>
                </div>
              </li>
              <li className="d-flex align-items-center mb-3">
                <i className="ti ti-check text-primary fs-2 me-2"></i>
                <div>
                  <h3 className="fw-semibold mb-1 lead">Secure & Reliable:</h3>
                  <span>
                    Access your workspace with built-in security features.
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="col-lg-6 d-flex justify-content-center align-items-center min-vh-lg-100">
          <div className="w-100" style={{ maxWidth: "28rem" }}>
            <div>
              <div className="d-flex d-lg-none justify-content-center mb-5">
                <Link to={routes.auth.home}>
                  <img src={logo} alt="castAPI" width={180} height={45} />
                </Link>
              </div>
              <div className="mb-4 text-center text-md-start">
                <span className="d-inline-block d-lg-block h1 mb-3 mb-lg-6 me-3">
                  👋
                </span>
                <h2 className="d-inline-block d-lg-block h2 fw-bolder">
                  {t("Nice to see you!")}
                </h2>
              </div>
            </div>

            <form onSubmit={handleSubmit(handleLoginAction)}>
              <Input
                label={t("Email address")}
                name="email"
                type="email"
                register={register}
                errors={errors}
                autoComplete="email"
                maxLength={255}
                focus={true}
              />

              <Input
                label={t("Password")}
                name="password"
                type="password"
                register={register}
                errors={errors}
                autoComplete="current-password"
                minLength={6}
              />

              <div className="mt-4 d-grid gap-2">
                <Button
                  type="button"
                  isLoading={isPending}
                  isDisabled={isPending || !isValid}
                  variant={"primary"}
                  size={"lg"}
                  onClick={handleLoginAction}
                >
                  <span>{t("Log in")}</span>
                </Button>
              </div>
            </form>

            <small className="divider-center d-flex align-items-center text-muted mt-4">
              {t("or")}
            </small>

            <div className="d-grid mt-4">
              <GitHubLogin />
            </div>

            <p className="text-muted mt-5 text-center text-md-start">
              <span>{t("Don't have an account yet?")}</span>
              <Link className="ms-1 text-muted" to={routes.public.signup}>
                {t("Sign up here")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
