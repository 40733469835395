import { useParams } from "react-router-dom";
import Create from "../../components/Endpoints/Views/Create";
import Edit from "../../components/Endpoints/Views/Edit";
import EndpointProvider from "../../providers/EndpointProvider";

const EndpointForm = () => {
  const { uuid } = useParams();

  return uuid ? (
    <EndpointProvider>
      <Edit />
    </EndpointProvider>
  ) : (
    <Create />
  );
};

export default EndpointForm;
