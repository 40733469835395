import { useTranslation } from "react-i18next";
import github from "../../assets/images/github.svg";

const GitHubLogin = ({ buttonText, redirect, isDisabled }) => {
  const { t } = useTranslation();

  const handleLogin = () => {
    const redirectUri = process.env.REACT_APP_GITHUB_REDIRECT;
    const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;

    if (redirect) {
      localStorage.setItem("redirectCastAPI", redirect);
    }
    // Redirect to GitHub login
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user:email&state=random_state_string`;
  };

  return (
    <button
      type="button"
      onClick={handleLogin}
      className="btn btn-light d-block w-100"
      disabled={isDisabled}
    >
      <div className="d-flex justify-content-center align-items-center gap-2">
        {buttonText ? (
          buttonText
        ) : (
          <>
            <img src={github} alt="GitHub icon" width={18} height={18} />
            <span>{t("Login with GitHub")}</span>
          </>
        )}
      </div>
    </button>
  );
};

export default GitHubLogin;
