import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./providers/AuthProvider";
import { OAuthLayout, AuthLayout, MainLayout } from "./layouts";
import { EndpointList, EndpointForm } from "./pages/Endpoints";
import { CollectionList, CollectionForm } from "./pages/Collections";
import { Signup, Login, GitHubCallback } from "./pages/Auth";
import Home from "./pages/Home/Home";
import { RequestLits } from "./pages/Requests";
import {
  SettingsAccount,
  SettingsBilling,
  SettingsSecurity,
  SettingsWorkspace,
} from "./pages/Settings";
import routes from "./configs/routes";
import "./assets/styles/global.scss";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route element={<OAuthLayout />}>
            <Route path={routes.public.github} element={<GitHubCallback />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path={routes.public.login} element={<Login />} />
            <Route path={routes.public.signup} element={<Signup />} />
          </Route>
          <Route element={<MainLayout />}>
            <Route path={routes.auth.home} element={<Home />} />
            <Route
              path={routes.auth.collections}
              element={<CollectionList />}
            />
            <Route
              path={routes.auth.collection.create}
              element={<CollectionForm />}
            />
            <Route
              path={routes.auth.collection.edit(":uuid")}
              element={<CollectionForm />}
            />
            <Route
              path={routes.auth.endpoints(":uuid")}
              element={<EndpointList />}
            />
            <Route
              path={routes.auth.endpoint.create(":collectionUuid")}
              element={<EndpointForm />}
            />
            <Route
              path={routes.auth.endpoint.edit(":uuid")}
              element={<EndpointForm />}
            />
            <Route
              path={routes.auth.requests(":uuid")}
              element={<RequestLits />}
            />
            <Route
              path={routes.auth.request.view(":uuid", ":requestUuid")}
              element={<RequestLits />}
            />
            <Route
              path={routes.auth.settings.account}
              element={<SettingsAccount />}
            />
            <Route
              path={routes.auth.settings.security}
              element={<SettingsSecurity />}
            />
            <Route
              path={routes.auth.settings.billing}
              element={<SettingsBilling />}
            />
            <Route
              path={routes.auth.settings.workspace}
              element={<SettingsWorkspace />}
            />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
