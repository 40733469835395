const Loading = ({ length }) => {
  return Array.from({ length: length || 2 }).map((_, index) => (
    <div key={index} className="mt-3 w-100 mb-3">
      <div className="card p-4 w-100">
        <div className="d-flex justify-content-between align-items-center">
          <div className="collections__details col-lg-6">
            <div className="placeholder-wave">
              <div className="placeholder h4 rounded col-4"></div>
            </div>
            <div className="placeholder-wave">
              <div className="placeholder rounded col-6"></div>
            </div>
          </div>
          <div className="collections__actions col-lg-6 d-flex align-items-center justify-content-end">
            <div className="placeholder-wave me-5" style={{ width: "115px" }}>
              <div className="placeholder rounded w-100"></div>
            </div>
            <div
              className="placeholder-wave me-3"
              style={{ width: "115px", height: "35px" }}
            >
              <div className="placeholder rounded w-100 h-100"></div>
            </div>
            <div
              className="placeholder-wave"
              style={{ width: "23px", height: "35px" }}
            >
              <div className="placeholder rounded w-100 h-100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

export default Loading;
