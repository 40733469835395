import { useEffect, useState } from "react";

const ParamToInput = ({ path, finalUrl }) => {
  const [urlPattern, setUrlPattern] = useState("");
  const [params, setParams] = useState({});

  // Function to extract parameters from URL pattern
  const extractParams = (pattern) => {
    const curlyParams = pattern.match(/\{([^}]+)\}/g) || [];
    const colonParams = pattern.match(/:([^/?&=]+)/g) || [];

    const newParams = {};

    // Handle {param} style
    curlyParams.forEach((param) => {
      const cleanParam = param.replace(/[{}]/g, "");
      newParams[cleanParam] = "";
    });

    // Handle :param style
    colonParams.forEach((param) => {
      const cleanParam = param.replace(":", "");
      newParams[cleanParam] = "";
    });

    return newParams;
  };

  useEffect(() => {
    setUrlPattern(path);
    setParams(extractParams(path));
  }, [path]);

  useEffect(() => {
    finalUrl(getFinalUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, finalUrl]);

  const generateMockValue = (key) => {
    const lowercaseKey = key.toLowerCase();

    // Handle UUID variations
    if (lowercaseKey.includes("uuid")) {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
    }

    // Handle ID variations
    if (lowercaseKey.includes("id")) {
      return Math.floor(Math.random() * 1000);
    }

    // Handle email variations
    if (lowercaseKey.includes("email")) {
      return `user${Math.floor(Math.random() * 1000)}@example.com`;
    }

    // Handle date variations
    if (lowercaseKey.includes("date")) {
      return new Date().toISOString().split("T")[0];
    }

    // Handle timestamp variations
    if (lowercaseKey.includes("timestamp")) {
      return Date.now();
    }

    // Handle name variations
    if (lowercaseKey.includes("name")) {
      const names = ["John", "Jane", "Bob", "Alice", "Charlie"];
      return names[Math.floor(Math.random() * names.length)];
    }

    // Handle status variations
    if (lowercaseKey.includes("status")) {
      const statuses = ["active", "pending", "completed", "failed"];
      return statuses[Math.floor(Math.random() * statuses.length)];
    }

    // Handle boolean variations
    if (lowercaseKey.includes("bool")) {
      return Math.random() > 0.5;
    }

    // Handle number variations
    if (lowercaseKey.includes("number")) {
      return Math.floor(Math.random() * 100);
    }

    // Randonmize some texts for the default case
    const texts = ["test", "example", "lorem", "ipsum", "castAPI", "mock"];
    return texts[Math.floor(Math.random() * texts.length)];
  };

  const handleParamChange = (paramName, value) => {
    setParams((prev) => {
      const newParams = {
        ...prev,
        [paramName]: value,
      };
      return newParams;
    });
  };

  const autoFillParams = () => {
    const newParams = { ...params };
    Object.keys(newParams).forEach((key) => {
      newParams[key] = generateMockValue(key);
    });
    setParams(newParams);
  };

  const getFinalUrl = () => {
    let finalUrl = urlPattern;
    Object.entries(params).forEach(([key, value]) => {
      finalUrl = finalUrl.replace(`{${key}}`, value || `{${key}}`);
      finalUrl = finalUrl.replace(`:${key}`, value || `:${key}`);
    });
    return finalUrl;
  };

  return (
    <>
      {Object.keys(params).length > 0 && (
        <>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="form-label">Parameters:</div>
            <button
              className="btn btn-transparent ms-auto d-flex align-items-center py-0 gap-1 text-gradient"
              onClick={autoFillParams}
            >
              <i className="ti ti-wand"></i>
              <span>Auto-fill</span>
            </button>
          </div>

          <div className="bg-light py-1 px-2 rounded mb-4">
            <table className="table table-striped mb-0">
              <thead className="visually-hidden">
                <tr>
                  <th scope="col">Key</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(params).map((param) => (
                  <tr>
                    <th scope="row" className="text-break rounded-start">
                      <div className="d-flex align-items-center">
                        <label
                          className="form-label m-0 mt-1 text-nowrap"
                          htmlFor={`param${param}`}
                        >
                          {param}:
                        </label>
                      </div>
                    </th>
                    <td className="rounded-end p-1">
                      <input
                        type="text"
                        id={param}
                        value={params[param]}
                        onChange={(e) =>
                          handleParamChange(param, e.target.value)
                        }
                        className="form-control"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default ParamToInput;
