import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchList } from "../../services/collectionService";
import Options from "../../components/Collections/Item/Options";
import ButtonLink from "../../components/Button/ButtonLink";
import Heading from "../../components/Heading/Heading";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard";
import { Empty, Loading } from "../../components/Collections/Messages";
import Error from "../../components/Errors/Error";
import routes from "../../configs/routes";
import { apiDomain } from "../../utils/helpers";
import "./Collection.scss";

const CollectionList = () => {
  const { t } = useTranslation();
  const { data, isPending, error } = useFetchList();
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!data || isPending) return;
    const { content: collections = [] } = data || {};
    setCollections(collections);
    setLoading(false);
  }, [data, isPending]);

  return (
    <>
      <Heading
        back={t("Home")}
        to={routes.auth.home}
        title={t("Collections")}
        actionButtons={
          collections?.length > 0 && (
            <ButtonLink href={routes.auth.collection.create}>
              <i className="ti ti-square-rounded-plus fs-4"></i>
              <span>{t("Create collection")}</span>
            </ButtonLink>
          )
        }
      />
      {error ? (
        <Error
          title={t("Something went wrong")}
          description={t("We are unable to fetch the collections right now.")}
          target={routes.auth.home}
          button={t("Back to home")}
        />
      ) : loading ? (
        <Loading />
      ) : (
        <div className="collections">
          {collections.length === 0 && !loading ? (
            <Empty />
          ) : (
            collections.map((collection) => (
              <div
                key={collection.uuid}
                className="collection__item card p-4 mb-3"
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="position-relative row w-100">
                    <div className="col col-lg-4">
                      <h5 className="mb-1">{collection.name}</h5>
                      {collection.description && (
                        <div className="text-muted small">
                          {collection.description}
                        </div>
                      )}
                    </div>
                    <div className="col col-lg-6 d-flex align-items-center">
                      <div className="position-relative z-3">
                        <CopyToClipboard
                          label={
                            <span className="font-monospace">
                              {apiDomain(collection.domain).replace(
                                /^https?:\/\//,
                                ""
                              )}
                            </span>
                          }
                          value={apiDomain(collection.domain)}
                        />
                      </div>
                    </div>
                    <Link
                      to={routes.auth.endpoints(collection.uuid)}
                      className="stretched-link z-1"
                    ></Link>
                  </div>

                  <div className="collections__actions d-flex align-items-center">
                    <div className="text-muted small">
                      <strong className="me-1">
                        {collection.endpointsCount}
                      </strong>
                      {collection.endpointsCount === "1" ? (
                        <span>{t("endpoint")}</span>
                      ) : (
                        <span>{t("endpoints")}</span>
                      )}
                    </div>
                    <div className="ms-2">
                      <Options uuid={collection.uuid} />
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
};

export default CollectionList;
