import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CollectionContext } from "../../../contexts/CollectionContext";
import EditForm from "../Form/EditForm";
import Heading from "../../Heading/Heading";
import DeleteAction from "../Item/Delete/ButtonDelete";
import routes from "../../../configs/routes";
import CollectionNav from "../../Heading/CollectionNav";

const Edit = () => {
  const { t } = useTranslation();
  // @todo: Implement the isPending and error variables
  // eslint-disable-next-line no-unused-vars
  const { collection, isPending, error } = useContext(CollectionContext);
  const [collectionData, setCollectionData] = useState({});

  useEffect(() => {
    if (!collection) return;
    setCollectionData(collection);
  }, [collection]);

  return (
    <>
      <Heading
        back={t("Collections")}
        to={routes.auth.collections}
        title={t("Edit collection")}
        actionButtons={<CollectionNav uuid={collectionData?.uuid} />}
      />
      <div className="sub-container">
        <EditForm collectionData={collectionData} />
        {collectionData?.uuid && (
          <div className="card p-4 mt-4">
            <div className="d-flex align-items-center gap-1 mb-4">
              <i className="ti ti-alert-triangle fs-4 text-danger" />
              <h3 className="fs-5 mb-0">{t("Danger zone")}</h3>
            </div>
            <div className="mb-3">
              <DeleteAction collectionUuid={collectionData.uuid} />
            </div>
            <p className="mb-0 small">
              {t(
                "This action will delete all endpoints and requests associated with this collection."
              )}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Edit;
