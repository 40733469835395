import { useEffect, useReducer, useCallback } from "react";
import { useTranslation } from "react-i18next";
import InputText from "../../Forms/InputText";
import InputNumber from "../../Forms/InputNumber";
import TextEditor from "../../Forms/TextEditor";
import Select from "../../Forms/Select";
import {
  formReducer,
  initialState,
} from "../../../reducers/endpoint/formReducer";
import { slugifyPath } from "../../../utils/helpers";
import InputHeaders from "./InputHeaders";
import InputFormData from "./InputFormData";
import { bodyTypes, methods } from "../../../utils/mimeTypes";
import StatusCodeSuggester from "./StatusCodeSuggester";

const Form = ({ endpointData, formContent }) => {
  const { t } = useTranslation();
  const [formData, dispatch] = useReducer(formReducer, initialState);

  useEffect(() => {
    formContent(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (!endpointData) return;
    dispatch({
      type: "SET_FORM_DATA",
      payload: endpointData,
    });
  }, [endpointData]);

  const handleInputChange = useCallback((key, value) => {
    dispatch({ type: "SET_FORM_DATA", payload: { [key]: value } });
  }, []);

  const handlePathChange = (value) => {
    const slug = slugifyPath(value);
    handleInputChange("path", slug);
  };

  const handleBodyChange = (value) => {
    const body = JSON.stringify(value);
    handleInputChange("body", body);
  };

  const handleSelectedStatusCode = (code) => {
    handleInputChange("code", code);
  };

  return (
    <div className="sub-container endpoint__details">
      <div className="card p-4">
        <div className="d-flex align-items-center gap-1 mb-4">
          <i className="ti ti-world fs-4 text-primary" />
          <h3 className="fs-5 mb-0">{t("Request configuration")}</h3>
        </div>
        <InputText
          id="name"
          label={t("Name")}
          defaultValue={formData?.name || ""}
          onChange={(value) => handleInputChange("name", value)}
          placeholder={t("Request name")}
          autoComplete="off"
          maxLength="64"
          required
        />
        <div className="row">
          <div className="col-3 col-lg-3">
            <Select
              name="method"
              label={t("Method")}
              className="w-100 d-flex justify-content-center"
              defaultValue={formData?.method}
              options={methods}
              onChange={(value) => handleInputChange("method", value)}
            />
          </div>
          <div className="col-lg-9">
            <InputText
              id="path"
              label={t("Path")}
              autoComplete="off"
              maxLength="255"
              minLength="3"
              value={formData?.path}
              onChange={(value) => handlePathChange(value)}
              placeholder={"/api/v1/users"}
            />
          </div>
        </div>
      </div>

      <div className="card p-4 mt-4">
        <div className="d-flex align-items-center gap-1 mb-4">
          <i className="ti ti-server-2 fs-4 text-primary" />
          <h3 className="fs-5 mb-0">{t("Response")}</h3>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <InputNumber
              id="code"
              label={t("Status code")}
              min="100"
              max="599"
              defaultValue={formData?.code}
              onChange={(value) => handleInputChange("code", value)}
            />
          </div>
          <div className="col-sm-6 col-md-6 col-lg-9 mt-4 pt-2">
            <StatusCodeSuggester
              title={formData?.name}
              description={formData?.description}
              onSelectStatusCode={handleSelectedStatusCode}
            />
          </div>
        </div>
        <InputHeaders
          defaultHeaders={formData?.headers}
          onChange={(value) => handleInputChange("headers", value)}
        />
        <div className="mt-4 row">
          <div className="col-sm-6 col-md-6 col-lg-2">
            <InputNumber
              id="delay"
              label={t("Delay (ms)")}
              defaultValue={formData?.delay ?? "0"}
              min="0"
              onChange={(value) => handleInputChange("delay", value)}
            />
          </div>
        </div>
      </div>

      <div className="card p-4 mt-4">
        <div className="d-flex align-items-center gap-1 mb-4">
          <i className="ti ti-code fs-4 text-primary" />
          <h3 className="fs-5 mb-0">{t("Body")}</h3>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-3">
          <Select
            name="type"
            label={t("Body type")}
            defaultValue={formData?.type}
            onChange={(value) => handleInputChange("type", value)}
            className="w-100 d-flex justify-content-center"
            options={bodyTypes}
          />
        </div>

        {formData?.type === "form-data" ||
        formData?.type === "form-url-encoded" ? (
          <InputFormData
            type={formData?.type}
            defaultData={formData?.body}
            onChange={(value) => handleBodyChange(value)}
          />
        ) : (
          <TextEditor
            id="body"
            label={t("HTTP response body")}
            defaultLanguage={formData?.type ?? "json"}
            defaultValue={formData?.body ?? ""}
            placeholder={`{"message": "Hello, World!"}`}
            onChange={(value) => handleInputChange("body", value)}
          />
        )}
      </div>
    </div>
  );
};

export default Form;
