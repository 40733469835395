import { useState } from "react";
import PropTypes from "prop-types";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const Alert = ({ children, variant, title, delay, position, area }) => {
  const [show, setShow] = useState(true);

  const mapVariantToIcon = (variant) => {
    switch (variant) {
      case "success":
        return "checks text-success";
      case "danger":
        return "alert-octagon text-danger";
      case "warning":
        return "alert-triangle text-warning";
      case "info":
        return "info-circle text-info";
      default:
        return "info-circle text-info";
    }
  };

  return (
    <ToastContainer
      className={`m-5 z-3 position-fixed ${area || "auto"}`}
      position={!area ? position || "top-center" : ""}
      aria-live="polite"
      aria-atomic="true"
    >
      <Toast
        autohide
        show={show}
        onClose={() => setShow(false)}
        delay={delay || 4000}
        className={`border-0 border-start border-${variant}-subtle border-5`}
      >
        <Toast.Body className="p-0">
          <div className="d-flex">
            <div className={`col-2 rounded flex-grow-1`}>
              <div className="p-3 d-flex align-items-center justify-content-center h-100">
                <i className={`ti ti-${mapVariantToIcon(variant)} fs-1`}></i>
              </div>
            </div>
            <div className="col-10 flex-grow-1">
              <div className="p-3 ps-1">
                {title && <strong className="d-block">{title}</strong>}
                <div>{children}</div>
              </div>
            </div>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Alert;
